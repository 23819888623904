export default {
    model: 'Processar',
    modelLabel: 'Número de variantes',
    availableVariants: 'Variantes disponíveis',
    variants: 'Variantes',
    editVariants: 'Entrar o número de variantes a agregar',
    variant: 'Variante',
    min: 'min',
    max: 'máximo',
    patterns: 'Padrões',
    loops: 'Retrabalho',
    loop: 'Loop',
    nLoops: 'Loops',
    selfLoops: 'Auto-laços',
    cases: 'traços',
    loopsFound: 'Encontrado em',
    options: 'Opções',
    percentageTotal: 'Mostrar % dos dados totais',
    percentageFiltered: 'Mostrar % de dados filtrados',
    changePercentage: 'Alterar % de referência',
    showLoopMetrics: 'Mostrar métricas do ciclo',
    changeGraphDirection: 'Definir a direção do gráfico',
    changeGraphMetric: 'Definir métrica de tempo',
    centerChart: 'Centrar o gráfico',
    cancel: 'Cancelar',
    filterVariants: 'Filtrar as variantes apresentadas',
    filterVariantsApplied: 'Filtro de variantes já aplicado',
    filterLoop: 'Filtrar o loop apresentado',
    filterLoopApplied: 'Filtro de laço já aplicado',
    filterLoopText: 'Filter by loop',
    resetMultipleVariants: 'Repor as variantes seleccionadas',
    aggregate: 'Agregado',
    individual: 'Individual',
    exportImageFormat: 'Exportar para formato de imagem',
    exportLoadingDialog: 'Exportando dados, aguarde. Formato: ',

    pending: 'Pendente',
    soon: 'Em breve',

    display: 'Exibir',
    stats: 'Estatísticas',
    kPIs: 'KPIs',
    heatMap: 'Mapa de calor',
    direction: 'Alternar a direção do gráfico',
    directionInfo: 'Alterna entre um layout de gráfico vertical ou horizontal',

    frequencies: 'frequência',
    freq: 'frequência',
    durations: 'duração',
    both: 'ambos',
    kPI: 'KPI',
    averages: 'média',
    variances: 'sd',
    median: 'mediana',
    off: 'desligado',

    resetOptions: 'Repor opções',
    centerView: 'Vista central',
    resetHidePanelLocation: 'Redefinir ou esconder painéis',
    modelMetricsToggle: 'Métricas do modelo',
    customPlotPanelsToggle: 'Informação de atributos',
    resetPanelLocation: 'Restaurar localização',
    loopMetricsToggle: 'Métricas do loop',
    zoomer: {
        fab: 'Editar actividades',
        edit: 'Editar activities apresentadas',
        create: 'Criar conjunto de dados a partir da edição atual',
        reset: 'Repor as actividades apresentadas',
        addGroup: 'Adicionar agrupamento',
        removeGroup: 'Eliminar',
        createGroup: 'Criar agrupamento',
        apply: 'Aplicar mudanças',
        goBack: 'Retornar',
        groupTab: 'Agrupamento de actividades',
        removeTab: 'Eliminar actividades',
        labelGroup: 'Atividade agrupada',
        labelActivities: 'Actividades incluídas',
        labelGroupName: 'Nome da atividade agrupada',
        selectActivities: 'Selecionar Atividades',
        groupName: 'Defina um nome para o grupo',
        datasetName: 'Defina um nome para o novo conjunto de datos',
        labelDatasetName: 'Nome do novo conjunto de datos',
        datasetTitle: 'Novo conjunto de datos',
        savedDatasetNotification: 'Novo conjunto de datos criado',
        zoomerConfigCreatedNotification: 'Definições de atividade actualizadas',
        restoreConfigNotification: 'Definições de atividade restauradas',
        loopsNotAvailable: 'Os laços não estão disponíveis com actividades agrupadas aplicadas. É possível criar um novo conjunto de dados para os visualizar.',
        createDatasetError: 'Configuração de agrupamento não definida',
        info: 'Este recurso permite a agregação visual ou remoção de atividades para que os usuários possam visualizar versões simplificadas do processo e só tem efeito na ' +
        'visualização do Processo. Outras visualizações não são afetadas por esta configuração. Se houver necessidade de que essas mudanças persistam e transformem o processo, ' +
        'por favor, use a opção "Criar conjunto de dados a partir da edição atual" no menu de edição de atividades.',
        labelInfo: 'Informações sobre como o Zoomer funciona',
        indicator: {
            activated: 'A visualização do processo está sendo afetada pela agregação/remoção de atividades. Clique com o botão esquerdo para desativar.',
            disabled: 'Clique com o botão esquerdo para reativar a agregação/remoção de atividades na visualização do processo.',
        },

    },
    tabs: {
        process: 'Processar',
        variants: 'Variantes',
        loops: 'Retrabalho',
        metrics: 'Métricas',
        traces: 'Traços',
        conformance: 'Conformidade',
        predictions: 'Previsões',
        contextComparison: 'Comparações',
        settings: 'Definições',
        assistant: 'Assistente de IA',
        tooltip: {
            process: 'Descubra como se comporta o seu processo',
            variants: 'Explore os diferentes caminhos do seu processo',
            loops: 'Inspecionar potenciais desperdícios e o seu impacto',
            metrics: 'Visualizar e exportar métricas de desempenho do processo',
            traces: 'Verificar as conclusões com base nos dados originais',
            conformance: 'Efetuar verificações de conformidade com modelos de processos',
            predictions: 'Descobrir como o seu processo se irá comportar',
            contextComparison: 'Comparar e exportar diferentes contextos analíticos',
            settings: 'Configurar parâmetros do processo',
            assistant: 'Um assistente de IA para o ajudar a compreender os seus dados',
        },
    },
    bpmnSave: 'Converter o modelo apresentado em BPMN',
    bpmnOK: 'O modelo foi gerado corretamente',
    bpmnNotShowable: 'O modelo não contém dados de exibição',
    bpmnModal: {
        title: 'Gerar modelo BPMN',
        name: 'Nome do modelo',
        submit: 'Salvar modelo',
    },
    help: `
        <strong>Impedindo o deslocamento e o zoom do gráfico na rolagem da página</strong><br><br>
        A ação de rolagem pode causar zoom involuntário no gráfico.<br><br>
        O site abaixo permite que a página role normalmente, sem afetar o gráfico. Os usuários podem ampliar o gráfico clicando nos controles de zoom.<br><br>
        Eles também podem aplicar zoom e panorâmica usando movimentos de dois dedos no gráfico para telas táteis.
    `,
    downloadPng: {
        label: 'Descarregar gráfico',
        button: `<strong>Descarregar png</strong><br><br>
            Certifique-se de que o gráfico esteja centralizado para baixar a imagem corretamente.
        `,
    },
    notifications: {
        notFound: {
            text: 'Nenhum {tipo} encontrado',
            btn: 'Tentar de novo',
        },
    },
    graph: {
        START: 'INICIAR',
        END: 'FIM',
    },
    renameVariantModal: {
        title: 'Mudar o nome da variante',
        name: 'Novo nome da variante',
        submit: 'Renomear',
    },
    variantRenameOK: 'A variante foi corretamente renomeada',
    variantRenameRequired: 'O nome é obrigatório',
    variantRenameLengthError: 'O nome deve ter entre 1 e 100 caracteres',
    dashboard: {
        variants: 'Variantes',
        variantsOver: 'Por frequência versus duração média',
        activities: 'Actividades',
        transitions: 'Transições',
        download: 'Exportar métricas',
        notifications: {
            download: {
                text: 'Métricas exportadas com sucesso.',
                powerbi: 'Ficheiro PBIX obtido com sucesso.',
            },
        },
        sortBy: {
            FREQUENCY: 'Por frequência',
            DURATION_AVG: 'Por duração média mais longa',
            DURATION_MAX: 'Por duração máxima mais longa',
            DURATION_MIN: 'Por duração mínima mais curta',
            DURATION_STD: 'Por tempo de desvio padrão mais longo',
        },
        heatmap: {
            source: 'Fonte',
            target: 'Objetivo',
            value: 'Valor',
        },
        axisLabels: {
            frequency: 'Frequência',
            duration: 'Duração',
            variantId: 'Variante',
            activityId: 'Atividade',
        },
        oneVariantFrequency: 'Mostrar a variante mais frequente',
        multipleVariantFrequency: 'Mostrar as {variantes} variantes mais frequentes',
        oneVariantAggregated: 'Mostrar 1 variante agregada',
        multipleVariantAggregated: 'Mostrar {variantes} variantes agregadas',
    },
    traces: {
        table: {
            total: '{rowsNumber} eventos de {filteredTraces} casos',
            totalDesc: 'encontrados com base nos filtros actuais',
            search: {
                placeholder: 'Filtrar por ID do caso',
            },
        },
        toPlot: 'Vista de gráfico',
        toTable: 'Vista de tabela',
        toDataSet: 'Criar um novo conjunto de dados a partir dos filtros actuais',
        createDataSet: 'Criar conjunto de dados a partir dos filtros',
        cancel: 'Cancelar',
        create: 'Criar',
        nameRequired: 'Deve ser fornecido um nome para o novo conjunto de dados',
        nameLength: 'O nome deve ter 3 ou mais caracteres',
        creatingDataset: 'Criar conjunto de dados...',
        datasetCreated: 'Foi criado um novo conjunto de dados a partir dos filtros actuais',
        downloadCSV: 'Exportar para CSV',
        downloadStarted: 'Download iniciado',
        downloadOK: 'Transferência concluída com êxito',
        exportDialog: {
            title: 'Exportar dados de eventos para CSV',
            subtitle: 'Selecionar atributos a incluir',
            name: 'Nome do atributo',
            type: 'Tipo de dados',
            attribute: 'atributo',
            selected: 'selecionado de',
            export: 'Gerar ficheiro CSV',
        },
        plot: {
            eventsShown: 'Mostrar eventos',
            eventsTo: 'para',
            start: 'De:',
            end: 'Para:',
            noEvents: 'Nenhum evento para mostrar',
        },
    },
    filters: {
        labels: {
            TRACE_STARTS_BEFORE: 'COMEÇA ANTES',
            TRACE_ENDS_BEFORE: 'TERMINA ANTES',
            TRACE_STARTS_AFTER: 'COMEÇA DEPOIS',
            TRACE_ENDS_AFTER: 'TERMINA DEPOIS',
            TRACE_STARTS_WITH_ACTIVITY: 'COMEÇA COM',
            TRACE_ENDS_WITH_ACTIVITY: 'TERMINA COM',
            TRACE_CONTAINS_ACTIVITIES: 'CONTÉM ACTIVIDADES',
            TRACE_NOT_CONTAINS_ACTIVITIES: 'NÃO CONTÉM ACTIVIDADES',
            TRACE_CONTAINS_ARCS: 'CONTÉM ARCOS',
            TRACE_NOT_CONTAINS_ARCS: 'NÃO CONTÉM ARCOS',
            TRACE_DURATION_GREATER_THAN: 'TRAÇO MAIOR QUE',
            TRACE_DURATION_LESS_THAN: 'TRAÇO MAIS CURTO QUE',
            ARC_DURATION_GREATER_THAN: 'ARCO MAIOR QUE',
            ARC_DURATION_LESSER_THAN: 'ARCO MAIS CURTO DO QUE',
            ACTIVITY_DURATION_GREATER_THAN: 'ACTIVIDADE MAIOR QUE',
            ACTIVITY_DURATION_LESS_THAN: 'ACTIVIDADE MAIS CURTA DO QUE',
            ACTIVITIES_DURATION_GREATER_THAN: 'DURAÇÃO ENTRE ACTIVIDADES MAIOR DO QUE',
            ACTIVITIES_DURATION_LESS_THAN: 'DURAÇÃO ENTRE ACTIVIDADES MENOR DO QUE',
            TRACE_IS_IN_VARIANTS: 'ESTÁ EM VARIANTES',
            TRACE_IS_IN_LOOP_VARIANTS: 'CONTÉM LOOP',
            TRACE_IS_IN_N_VARIANTS: 'ESTÁ NAS N VARIANTES MAIS FREQUENTES',
        },
        analysisContext: 'Contexto de análise',
        modelMetrics: 'Métrica do modelo',
        contextual: 'Contexto de análise',
        total: 'Filtros ({total})',
        title: 'Escolha um filtro para adicionar',
        titleOpenFilter: 'Configurar filtro selecionado',
        manage: 'Adicionar',
        sets: 'Conjuntos',
        cancel: 'Fechar',
        reset: 'Repor',
        empty: 'Nenhum filtro adicionado, clique em "Adicionar" para adicionar o seu primeiro filtro',
        emptyModel: 'Não foram encontrados vestígios para os filtros actuais',
        sidebarTooltips: {
            durationMetric: "Escolher métrica de duração",
            addFilter: "Adicionar filtro",
            resetActiveFilters: "Redefinir filtros ativos",
            manageFilterSets: "Gerenciar conjuntos de filtros",
            createFilterSet: "Criar conjunto de filtros",
            changeFilterAggregation: "Alterar operador de agregação do filtro",
        },
        activities: {
            title: 'Actividades',
            label: 'Selecionar uma atividade',
            activityOccurrence: 'Por ordem de ocorrência da atividade',
            pairRepetition: 'Por repetição de pares',
        },
        arcs: {
            title: 'Arcos',
            label: 'Selecionar um arco',
        },
        constraints: {
            label: 'Selecionar restrições de atributos',
        },
        date: {
            title: 'Data e hora',
            label: 'Data e hora',
            noData: 'Sem dados',
            max: {
                label: '{Máx.}',
                tooltip: 'Data final',
            },
            min: {
                label: '{min}',
                tooltip: 'Data de início',
            },
        },
        duration: {
            title: 'Duração',
            label: 'Duração',
            avg: '{d}d {h}h {m}m {s}s',
            avgTooltip: 'Média: {d}d {h}h {m}m {s}s',
            min: 'Mín: {d}d {h}h {m}m {s}s',
            max: 'Máximo: {d}d {h}h {m}m {s}s',
            sd: 'Dev: {d}d {h}h {m}m {s}s',
            duration: '{d}d {h}h',
            secondDuration: '{h}h {m}m',
            minorDuration: '{m}m {s}s',
            filters: {
                trace: 'Duração do traço',
                activity: 'Duração da atividade',
                arc: 'Duração do arco',
                activities: 'Duração entre actividades',
            },
        },
        attributes: {
            title: 'Atributos',
            label: 'Atributos',
            value: {
                label: 'Valor',
            },
            categoryAny: 'Qualquer',
            attrIs: 'É',
            attrIsNot: 'NÃO É',
            attrIsTrueLabel: 'É VERDADEIRO',
            attrIsFalseLabel: 'É FALSO',
            attrNumEqualsLabel: '=',
            attrNumNotEqualsLabel: '!=',
            attrNumGreaterThanLabel: 'MAIOR DO QUE',
            attrNumLesserThanLabel: 'MENOR QUE',
            attrNumBetweenLabel: 'ENTRE',
            attrBetweenLabel: 'ENTRE',
            attrDateAfterLabel: 'DEPOIS',
            attrDateBeforeLabel: 'ANTES',
            attrDateBetweenLabel: 'ENTRE',
            attrGreaterLabel: 'MAIS LONGO DO QUE',
            attrLesserLabel: 'MAIS CURTO DO QUE',
            filters: {
                numericalField: 'Numérico',
                ctgField: 'Categórico / Textual',
                booleanField: 'Booleano',
                dateTimeField: 'Data / Hora',
            },
            table: {
                title: 'Atributos seleccionados',
                activity: 'Atividade',
                attribute: 'Atributo',
                constraints: 'Restrição',
                value: 'Valor',
                delete: '',
            },
        },
        save: 'Guardar',
        add: 'Adicionar',
        totalProgress: '{valor} de {total}',
        progress: [
            { id: 'casos', label: 'Traços' },
            { id: 'variantes', label: 'Variantes' },
            { id: 'actividades', label: 'Actividades' },
            { id: 'transições', label: 'Transições' },
        ],
        contextualInfo: {
            total: 'Total',
            filtered: 'Filtradas',
            displayed: 'Exibidos',
            cases: 'Casos',
            variants: 'Variantes',
            duration: 'Duração',
            med: 'Mediana (med)',
            avg: 'Média (avg)',
            max: 'Máximo (max)',
            min: 'Mínimo (min)',
            sd: 'Desvio padrão (sd)',
            maxEndDateTime: 'Data final',
            minStartDateTime: 'Data de início',
            estimatedCost: 'Custo est. Custo',
            totalEstimatedCost: 'Custo est. Custo total',
            expandLess: 'Mostrar menos',
            expandMore: 'Mostrar mais',
        },
        filterSets: {
            title: 'Conjunto de filtros',
            name: 'Conjunto de filtros',
            createNewFilterSet: 'Criar conjunto de filtros',
            register: {
                success: 'Conjunto de filtros registado corretamente',
            },
            delete: {
                success: 'Conjunto de filtros eliminado corretamente',
                title: 'Apagar conjunto',
                description: 'Tem a certeza de que pretende eliminar este grupo de filtros?',
            },
            search: {
                title: 'Adicionar um grupo de filtros',
                placeholder: 'Introduzir o nome do novo grupo de filtros',
            },
            notifications: {
                noResult: {
                    title: 'Não foram encontrados conjuntos de filtros',
                    noFilters: 'O grupo de filtros selecionado não contém quaisquer filtros',
                },
            },
            config: {
                title: 'Configuração do sistema de filtros',
                aggregationOperators: {
                    title: 'Operador de agregação',
                    AND: 'E',
                    OR: 'OU',
                },
                filterSetsTable: {
                    load: 'Carregar',
                    loadAsFilterSet: 'Como conjunto de filtros',
                    loadAsFilters: 'Como filtros individuais',
                    title: 'Conjuntos de filtros',
                    new: 'Novo conjunto de filtros',
                    addToActive: 'Adicionar aos filtros ativos',
                    replaceActive: 'Substituir filtros ativos',
                    removeSelected: 'Remover selecionado',
                    filterName: 'Nome',
                    operator: 'Operador',
                    filtersIncluded: 'Filtros incluídos',
                    filters: 'filtros',
                    filter: 'filtro',
                },
            },
        },
    },
    loopMetrics: {
        metricsTitle: 'Métricas do ciclo',
        avgTime: 'Tempo médio',
        avgCost: 'Custo médio',
        percentTime: '% da duração média do caso',
        estTotalEffort: 'Esforço total estimado',
        afterRemoval: 'Melhoria do caso após a remoção',
        avgCostConf: 'Custo médio/h',
        currency: 'Moeda',
    },
    customPlotPanels: {
        frequencyAndDuration: 'Frequência e duração',
        attributeInfo: 'Informação sobre o atributo',
        frequency: 'Frequência',
        duration: 'Duração',
        frequencyDuration: {
            selectDuration: 'Selecionar unidade de tempo',
            selectRange: 'Selecionar intervalo',
        },
        attributesInfo: {
            selectAttribute: 'Selecionar atributo',
            sortBy: 'Ordenar por:',
            start: 'início',
            end: 'fim',
            month: 'mês',
            week: 'semana',
            day: 'dia',
            eventAttribute: 'Repartição por atividade',
            selectActivity: 'Selecionar uma atividade',
            activityRequired: 'Selecionar pelo menos uma atividade',
            noData: 'Não existem actividades que contenham este atributo',
            selectUnit: 'Selecionar uma unidade',
        },
    },
    conformance: {
        chooseBpmn: 'Selecionar um modelo BPMN',
        overview: 'Conformidade',
        issues: 'Principais problemas',
        root: 'Causas principais',
        noModels: 'Nenhum modelo BPMN disponível atualmente para este projeto.' +
            'É necessário ter pelo menos um modelo BPMN para analisar a conformidade.',
        noModelsUpload: 'É possível criar um modelo a partir de qualquer visualização de gráfico no separador Processo ou carregar um.',
        fulfillment: {
            fulfillment: 'Cumprimento',
            percentageTitle: 'Taxa de conformidade do modelo',
            correct: 'Esperado',
            incorrect: 'Inesperado',
            percentageTimeTitle: 'Rácio de conformidade ao longo do tempo',
            validPercentageTime: 'Casos esperados %',
            invalidPercentageTime: 'Casos inesperados %',
            days: 'dias',
            weeks: 'semanas',
            months: 'meses',
            selectUnit: 'Selecionar unidade de tempo',
        },
        issuesView: {
            transitionsTitle: 'Transições inesperadas',
            startTitle: 'Actividades de início inesperado',
            endTitle: 'Actividades de fim inesperado',
            case: 'caso',
            cases: 'casos',
            outOf: 'fora de',
            noResultsTransitions: 'Não foram encontradas transições inesperadas',
            noResultsStart: 'Não foram encontradas actividades de início inesperado',
            noResultsEnd: 'Não foram encontradas actividades de fim inesperado',
        },
        rootCause: {
            noAttributes: 'Não há atributos disponíveis neste conjunto de dados',
        },
    },
    settings: {
        name: 'Definições',
        description: 'Configure os parâmetros do processo do seu conjunto de dados',
        pendingChanges: 'Há alterações pendentes ainda não guardadas',
        type: {
            general: 'Geral',
            insights: 'Análises',
        },
        caseCompletion: {
            name: 'Condições de conclusão do processo',
            endingActivities: 'Terminar actividades',
            occurringActivities: 'Actividades em curso',
            startingActivities: 'Início das actividades',
        },
        costs: {
            name: 'Custo e moeda',
            unit: 'Moeda',
            value: 'Valor',
            units: {
                euro: 'Euro (€)',
                dollar: 'Dólar ($)',
                pound: 'Libra (£)',
            },
            activities: 'Atividades',
            avgCostHour: 'Custo Médio/Hora',
            general: 'Geral',
            activityCosts: 'Custos das Atividades',
            duplicateError: 'Uma ou mais atividades selecionadas já têm custos atribuídos.',
            addCost: 'Adicionar Custo',
            removeCost: 'Remover Custo',
            noData: 'Nenhum dado disponível',
            ActivityCostModal: {
                editActivityCost: 'Editar Custo da Atividade',
                addActivityCost: 'Adicionar Custo da Atividade',
                selectActivities: 'Selecionar Atividades',
                costValue: 'Valor do Custo',
                add: 'Adicionar',
                save: 'Salvar',
                positiveNumberError: 'O valor do custo deve ser um número positivo',
                selectAtLeastOneActivity: 'Por favor, selecione pelo menos uma atividade.',
            },
        },
        sla: {
            name: 'Duração meta',
            number: 'Valor',
            unit: 'Unidade',
            units: {
                minutes: 'minutos',
                hours: 'horas',
                days: 'dias',
                weeks: 'semanas',
                months: 'meses',
            },
            globalSla: {
                tab: 'Global',
                enableGlobal: 'Habilitar duração meta global',
                compliance: 'Cumprir a duraçao meta',
                nonCompliance: 'Desempenhar-se abaixo da duraçao meta',
                cases: 'casos',
                name: 'Duraçao meta',
                description: 'Separa as execuções boas das ruins. Quando a duração de um caso é menor que o' +
                    'limite estabelecido, considera-se que está cumprindo a duraçao meta e, portanto, é uma execução aceitável',
                backButton: 'Back',
                minCase: 'Duração mínima',
                maxCase: 'Duração máxima',
            },
            caseSla: 'Variante de caso',
            saveButton: 'Salvar',
            customSla: 'Duração meta personalizada',
            addSla: 'Adicionar duração meta',
            activitySla: 'Duração meta da atividade',
            transitSla: 'Duração da transição meta',
            activityGapSla: 'Duração meta de atividades não contíguas',
            removeSla: 'Remover',
            add: 'Adicionar',
            edit: 'Editar',
            close: 'Fechar',
            variantsSlaPresent: 'Durações meta de variantes adicionais',
            customSlaPresent: 'Duração adicional da meta personalizada',
            variantsAndCustomSlaPresent: 'Durações meta personalizadas e variantes adicionais',
            variantSlaModal: {
                title: 'Adicionar duração meta da variante',
                selectVariant: 'Selecione uma variante',
                all: 'Todos',
            },
            activitySlaModal: {
                addTitle: 'Adicionar duração meta de atividade',
                editTitle: 'Editar duração da meta de atividade',
                selectActivity: 'Selecione uma atividade',
            },
            transitionSlaModal: {
                addTitle: 'Adicionar duração alvo da transição',
                editTitle: 'Editar duração da meta de transição',
                selectTransition: 'Selecione uma transição',
            },
            activityGapSlaModal: {
                addTitle: 'Adicionar duração alvo entre atividades não adjacentes',
                editTitle: 'Editar duração alvo entre atividades não adjacentes',
                selectFirstActivity: 'Selecione a primeira atividade',
                selectSecondActivity: 'Selecione a segunda atividade',
                firstToLast: 'Primeira a última',
                consecutiveLoop: 'Por ocorrência',
                sourceOcurrence: 'Ocorrência da primeira atividade',
                targetOccurrence: 'Segunda ocorrência de atividade',
                first: 'Primeira',
                last: 'Última',
                firstToLastHelp: 'Primeiro a último: Verifica se a duração desejada é atingida desde a primeira/última ocorrência da primeira atividade ' +
                'até à primeira/última ocorrência da segunda atividade dentro do rastreio. ',
                consecutiveLoopHelp: 'Por ocorrência: Se existirem loops em algum trace, verificar se a duração alvo foi atingida' +
                'todas as sequências formadas pelas actividades seleccionadas',
            },
            customSlaTable: {
                type: 'Tipo',
                elements: 'Elementos',
                sla: 'Duração meta',
                notifyDuplicated: 'A nova duração meta não pôde ser adicionada porque já existe uma com os mesmos parâmetros',
                loopMode: 'Modo de ocurrência',
                sourceOccurrence: 'Fonte',
                targetOccurrence: 'Fim',
            },
        },
        predictionAlgorithm: {
            name: 'Algoritmos de previsão',
            chooseAlgorithm: 'Selecionar algoritmo de previsão',
            algorithms: {
                standard: {
                    name: 'Padrão',
                    description: 'Método estatístico mais rápido. Proporciona uma boa precisão global, mas não é adaptado a cada conjunto de dados individual.',
                },
                machineLearning: {
                    name: 'Aprendizagem automática',
                    description: 'Método mais exato. Os modelos neurais são treinados com base no conjunto de dados atual, fornecendo os melhores resultados gerais.' +
                    'Depending on the data set size, the models may take some time to be ready for use.',
                    caseDurationCheck: 'Duração do caso e cumprimento da duração meta:',
                    activitySequenceCheck: 'Activity sequence prediction:',
                },
            },
            disabled: 'É necessário definir as condições de conclusão do caso e da duração meta para configurar o algoritmo de previsão',
        },
        relevantAttributes: {
            name: 'Atributos relevantes',
            attributes: 'Atributos',
            maxAttributes: '3',
            chooseRelevant: 'Selecionar atributos relevantes',
            noRelevant: 'Não foram seleccionados atributos relevantes',
            noSelectableAttributes: 'Não há atributos categóricos para selecionar',
            disabled: 'Não existem atributos válidos que permitam a configuração dos atributos relevantes',
        },
        saveSettings: 'Guardar configuraçoes',
        settingsSaved: 'Guardado com êxito',
        caseCompletionConditionsNoConf: 'Não há condições seleccionadas',
        caseCompletionConditionsConf: 'Condições seleccionadas',
        startingActivities: 'Início das actividades',
        occurringActivities: 'Actividades em curso',
        endingActivities: 'Terminar actividades',
        conditionsSelected: 'Condições seleccionadas',
        activities: 'Actividades',
    },
    predictions: {
        downloadCSV: 'Exportar para CSV',
        caseId: 'ID do caso',
        caseMetrics: 'Métricas do caso',
        estimatedDuration: 'Duração estimada',
        lastActivity: 'Última atividade',
        estimatedVariant: 'Variante',
        currentDuration: 'Duração atual',
        riskLevel: 'Nível de risco',
        nextActivity: 'Próxima atividade a ser executada',
        unifinishedCases: 'Casos inacabados',
        noRisk: 'Sem risco',
        risk: 'Risco',
        days: 'dias',
        table: {
            total: '{total} casos inacabados',
            totalDesc: 'encontrados com base nas definições actuais',
            search: {
                placeholder: 'Filtrar por ID do caso',
            },
            newVariant: 'Nova variante',
        },
        newVariant: 'Nova variante, previsão ainda não disponível.',
        loadingMessage: 'A carregar dados de previsão, por favor aguarde',
        slaNotDefined: 'As condições da duração meta não foram definidas para obter a previsão',
        caseCompletionNotDefined: 'As condições de conclusão do caso não foram definidas para obter a previsão',
        slaAndCaseNotDefined: 'As condições de conclusão do caso e da duração meta não foram definidas para obter a previsão',
        noPredictionsText: 'Pode definir as condições de previsão no separador Definições ou clicando no botão seguinte.',
        noData: 'Não existem dados disponíveis',
        notAvailable: 'A previsão não está disponível devido à falta de dados históricos. ' +
            'Deverá estar disponível quando mais casos de processos forem carregados e processados ao longo do tempo.',
        someNotAvailable: 'A previsão não está disponível para alguns casos devido à falta de dados históricos. ' +
            'Os casos afectados foram omitidos das informações fornecidas nesta visualização.',
    },
    contextComparison: {
        addContext: 'Adicionar contexto',
        downloadCSV: 'Exportar para CSV',
        comparison: 'Comparação',
        cases: 'Casos',
        variants: 'Variantes',
        duration: 'Duração',
        dates: 'Datas',
        start: 'Início',
        end: 'Fim',
        before: 'ANTES',
        after: 'DEPOIS',
        noData: 'Nenhum contexto adicionado',
        noDataExplanation: 'Adicionar contextos para comparar',
        maxContext: 'Máximo de oito contextos de comparação.',
        datasets: 'Conjuntos de dados',
        filtersets: 'Conjuntos de filtros',
        noFiltersets: '*Não existem conjuntos de filtros disponíveis',
    },
    powerBIDialog: {
        powerBI: 'Power BI',
        title: 'Conecte a análise de inteligência de processos da Inverbis ao Microsoft Power BI',
        clipboard: 'Copiar para a área de transferência',
        chooseConnection: 'Escolha o método de ligação que melhor se adequa às suas necessidades.' +
            'Observe que em ambos os casos você precisará fornecer o identificador do conjunto de dados atual e os parâmetros válidos do token de API.',
        currentDataset: 'Identificador do conjunto de dados atual:',
        tokenAvailable: 'O token de API está disponível',
        noTokenAvailable: 'Nenhum token de API foi gerado.',
        generateToken1: 'Você pode',
        generateToken2: 'gerar o seu próprio token',
        generateToken3: 'através da opção API no menu do seu perfil de usuário.',
        downloadButton1: `Descarregar .pbix para`,
        downloadButton2: `Power BI Desktop `,
        downloadExplanation: 'Descarregue um modelo pré-construído para a sua aplicação de ambiente de trabalho. Pode modificá-lo e adaptá-lo às suas necessidades.',
        installButton1: `Instale o aplicativo Inverbis em`,
        installButton2: `sua própria conta`,
        installExplanation: 'Instale o aplicativo de painel da Inverbis em sua própria assinatura de nuvem do Power BI para manter acesso on-line ao painel do seu processo.',
    },
    SLAInfo: {
        title: 'Informação da duração meta',
        cases: 'Casos',
        noSLAInfo: 'Meta não aplicável',
        error: 'Não há meta disponível',
    },
    inputNum: {
        IntNumError: 'O número suporta até {maxIntNum} digitos inteiros.',
        DecimalNumError: 'O número suporta até {max Decimal Number} digitos decimais.',
        DecimalNotAllowed: 'O número deve ser inteiro.',
    },
    frequencyIndicator: {
        percentOfActivities: '% de atividades mostradas',
    },
    popup: {
        buttons: {
            start_with: 'COMEÇA COM',
            end_with: 'TERMINA COM',
            contain: 'CONTÉM',
            not_contain: 'NÃO CONTÉM',
        },
        impact: {
            title: 'Impacto',
            current: 'Atual',
            total: 'Total',
            currentImpact: '% de impacto atual',
            totalImpact: '% de impacto sobre o total',
            level: {
                low: 'Baixo',
                medium: 'Médio',
                high: 'Alto',
            },
        },
        composed: 'Composta por:',
    },
}
