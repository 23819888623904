<template>
    <div>
        <div
            style="display:flex;"
            class="selectContainer"
        >
            <QSelect
                v-model="attribute"
                dense
                class="attrSelect"
                :options="optionAttributes.map(i => i.name)"
                :label="$t('visualization.customPlotPanels.attributesInfo.selectAttribute')"
                @update:modelValue="getData"
            />
            <div style="width:auto">
                <q-icon
                    key="dropdown"
                    name="more_vert"
                    size="30px"
                    class="openIcon"
                    color="primary"
                    clickable
                    @click="setSelected"
                />
                <Dropdown
                    v-if="selected"
                    :modelValue="title"
                    direction="bottomRight"
                    :opened="selected"
                    :options="metricOptions"
                    @on-click="handleClickOption"
                />
            </div>
        </div>
        <div
            v-if="!numericAttribute"
            class="flex flex-center q-ma-xs"
        >
            <WText
                :size="14"
                style="margin-right:10px"
            >
                {{ $t('visualization.customPlotPanels.attributesInfo.sortBy') }}
            </WText>
            <QBtnToggle
                v-model="currentSel.value"
                toggleColor="primary"
                noCaps
                rounded
                padding="2px 10px"
                :options="freqDur"
                @update:modelValue="getData"
            />
        </div>
        <div
            v-if="!emptyRows"
            class="chart-container"
        >
            <v-chart
                :option="barChartOptions"
                class="chart"
                autoresize
            />
        </div>
        <div
            v-else
            class="flex flex-center q-mx-md q-my-lg"
        >
            <WText>
                {{ $t('visualization.customPlotPanels.attributesInfo.noData') }}
            </WText>
        </div>
        <div
            v-if="!numericAttribute"
            class="flex flex-center q-ma-xs"
        >
            <div class="flex row-center">
                <WText>
                    {{ $t('visualization.customPlotPanels.attributesInfo.eventAttribute') }}
                </WText>
                <QToggle
                    v-model="eventAttribute"
                    color="primary"
                    keepColor
                />
            </div>
            <div
                v-if="eventAttribute"
                class="flex row-center"
            >
                <QSelect
                    ref="activities"
                    v-model="activities"
                    class="Field"
                    :label="$t('visualization.customPlotPanels.attributesInfo.selectActivity')"
                    :multiple="true"
                    :options="optionActivities"
                    :rules="[value => validators.required(value.length > 0) || $t('visualization.customPlotPanels.attributesInfo.activityRequired')]"
                >
                    <template #option="scope">
                        <QItem v-bind="scope.itemProps">
                            <QItemSection>
                                <QItemLabel v-html="scope.opt" />
                            </QItemSection>
                            <QItemSection side>
                                <QToggle
                                    v-model="scope.selected"
                                    :val="scope.opt"
                                    @update:modelValue="handleActivitiesInput(scope.opt)"
                                />
                            </QItemSection>
                        </QItem>
                    </template>
                </QSelect>
            </div>
        </div>
        <div v-else>
            <QSelect
                v-model="timeUnit"
                class="timeUnitField"
                dense
                :label="$t('visualization.customPlotPanels.attributesInfo.selectUnit')"
                :options="optionTimeUnit"
                @update:modelValue="getData"
            />
            <div
                class="q-pa-xs"
                style="display:flex; flex-direction:row; justify-content: space-around;"
            >
                <div style="display:flex; flex-direction:column;">
                    <div><strong>avg:</strong> {{ avg }}</div>
                    <div><strong>min:</strong> {{ min }}</div>
                </div>
                <div style="display:flex; flex-direction:column;">
                    <div><strong>max:</strong> {{ max }}</div>
                    <div><strong>sd:</strong> {{ sd }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import { filtersMixin, validatorsMixin, filtersStorageMixin } from '@/mixins'
import { Dropdown } from '@/components'
import {
    Api,
    apiRequest,
    notifyError,
} from '@/api'

export default {
    name: 'AttributeInformationPanel',
    components: { Dropdown },
    mixins: [filtersMixin, validatorsMixin, filtersStorageMixin],
    inject: ['App'],
    props: {
        currentTitle: VueTypes.object,
        attributes: VueTypes.array,
        categoricalAttributes: VueTypes.object,
        processId: VueTypes.string,
        filters: VueTypes.array,
        chart: VueTypes.object,
    },
    emits: ['title', 'chartLoading'],
    data () {
        return {
            title: this.currentTitle,
            selected: false,
            select: null,
            range: '',
            model: null,
            modifiedFilters: [],
            duration: null,
            stringOptions: '',
            metricOptions: [{
                key: 'FD', label: this.$t('visualization.customPlotPanels.frequencyAndDuration'), disabled: false, icon: 'timelapse',
            },
            {
                key: 'AI', label: this.$t('visualization.customPlotPanels.attributeInfo'), disabled: true, icon: 'query_stats',
            }],
            attribute: null,
            optionAttributes: [],
            eventAttribute: false,
            activities: [],
            selectedActivities: [],
            optionActivities: [],
            activitiesTimer: null,
            emptyRows: false,
            maxDur: 'days',
            numericAttribute: false,
            optionTimeUnit: [
                { unit: 'month', label: this.$t('visualization.customPlotPanels.attributesInfo.month') },
                { unit: 'week', label: this.$t('visualization.customPlotPanels.attributesInfo.week') },
                { unit: 'day', label: this.$t('visualization.customPlotPanels.attributesInfo.day') },
            ],
            timeUnit: { unit: 'month', label: this.$t('visualization.customPlotPanels.attributesInfo.month') },
            currentSel: {
                value: 'FREQUENCY',
                label: this.$t('visualization.customPlotPanels.frequency'),
            },
            avg: undefined,
            min: undefined,
            max: undefined,
            sd: undefined,
            barChartOptions: {
                title: {
                    text: '',
                },
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    // formatter: (params) => {
                    //     let tooltipContent = '<div style="max-width: 15em;">'
                    //     tooltipContent += `${this.lineBreak(params[0].name)}`
                    //     params.forEach((param) => {
                    //         const { seriesName } = param
                    //         const { value } = param
                    //         const { color } = param
                    //         tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                    //             </div>`
                    //         if (seriesName === this.$t('visualization.customPlotPanels.duration')) {
                    //             tooltipContent += `${seriesName}: ${moment.duration(value, this.maxDur).format('d[d] h[h]:m[m]:s[s]',
                    //                 { largest: 2, trim: false })} <br>`
                    //         } else {
                    //             tooltipContent += `${seriesName}: ${value} <br>`
                    //         }
                    //         tooltipContent += '</div>'
                    //     })
                    //     return tooltipContent
                    // },
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {},
                },
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: value => `${this.formatNumber(value)}${this.range}`,
                        },
                    },
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: value => this.formatNumber(value),
                        },
                    },
                ],
                dataZoom: [
                    {
                        type: 'inside',
                        show: true,
                        start: 0,
                        end: 100,
                        handleSize: 8,
                    },
                ],
                grid: {
                    left: 45,
                    right: 45,
                    bottom: 23,
                    top: 20,
                },
                series: [
                    {
                        name: this.$t('visualization.customPlotPanels.duration'),
                        type: 'line',
                        data: [],
                        itemStyle: {
                            color: '#33699A',
                        },
                        yAxisIndex: 0,
                    },
                    {
                        name: this.$t('visualization.customPlotPanels.frequency'),
                        type: 'bar',
                        data: [],
                        itemStyle: {
                            color: '#6C97BE',
                        },
                        yAxisIndex: 1,
                    },
                ],
            },
        }
    },
    computed: {
        freqDur () {
            return [{
                value: 'FREQUENCY',
                label: this.$t('visualization.customPlotPanels.frequency'),
            },
            {
                value: 'DURATION',
                label: this.$t('visualization.customPlotPanels.duration'),
            }]
        },
    },
    watch: {
        filters (newValue, oldValue) {
            let equal = false
            if (newValue.length === oldValue.length) {
                if (newValue.length === 0) {
                    equal = true
                } else {
                // eslint-disable-next-line no-plusplus
                    for (let i = 0; i < newValue.length; i++) {
                        if (newValue[i] !== oldValue[i]) {
                            equal = true
                        }
                    }
                }
            }
            if (equal === false) {
                this.getData()
            }
        },
        eventAttribute () {
            this.actiSel()
            this.getData()
        },
        chart () {
            this.getData()
        },
    },
    mounted () {
        this.attrSel()
        this.getData()
    },
    methods: {
        attrSel () {
            const optionAttr = this.attributes.filter((item) => {
                const isNumericType = ['BOOLEAN', 'SHORT', 'INTEGER', 'LONG', 'DECIMAL'].includes(item.type)
                const isStringAndInCategorical = item.type === 'STRING' &&
                    this.categoricalAttributes.some(cat => cat.name === item.name)
                return isNumericType || isStringAndInCategorical
            })
            this.resetChart()
            this.optionAttributes = optionAttr
            const attr = this.optionAttributes[0].name
            this.attribute = attr
        },
        actiSel () {
            this.resetChart()
            if (!this.chart || !this.chart.activities) {
                this.optionActivities = []
                this.activities = []
                this.selectedActivities = []
                return
            }
            const optionActi = this.chart.activities.filter(item => !['START', 'END'].includes(item.name))
            this.optionActivities = optionActi.map(item => item.name).sort()
            const acti = this.optionActivities[0]
            this.activities = [acti]
            this.selectedActivities = this.activities
        },
        formatNumber (num) {
            switch (true) {
                case num >= 1000000:
                    return `${parseFloat((num / 1000000).toFixed(1))}M`
                case num >= 100000:
                    return `${(num / 1000).toFixed(0)}K`
                case num >= 1000:
                    return `${parseFloat((num / 1000).toFixed(1))}K`
                default:
                    return parseFloat(num.toFixed(1))
            }
        },
        rangeDuration (seconds) {
            let rangeDur = ''
            switch (true) {
                case seconds >= 60 * 60 * 24:
                    rangeDur = 'days'
                    this.range = 'd'
                    break
                case seconds >= 60 * 60:
                    rangeDur = 'hours'
                    this.range = 'h'
                    break
                case seconds >= 60:
                    rangeDur = 'minutes'
                    this.range = 'm'
                    break
                default:
                    rangeDur = 'seconds'
                    this.range = 's'
            }
            return rangeDur
        },
        formatSeconds (seconds, rangeDur) {
            let dur = 0
            switch (rangeDur) {
                case 'days':
                    dur = moment.duration(seconds, 'seconds').asDays()
                    break
                case 'hours':
                    dur = moment.duration(seconds, 'seconds').asHours()
                    break
                case 'minutes':
                    dur = moment.duration(seconds, 'seconds').asMinutes()
                    break
                default:
                    dur = seconds
            }
            return dur
        },
        setSelected () {
            this.selected = !this.selected
        },
        handleClickOption (sel) {
            this.$emit('title', sel)
            this.setSelected()
        },
        handleActivitiesInput (selected) {
            if (!this.activities.includes(selected)) {
                this.activities.push(selected)
            } else {
                const index = this.activities.indexOf(selected)
                if (index !== -1) {
                    this.activities.splice(index, 1)
                }
            }
            this.selectedActivities = this.activities
            clearTimeout(this.activitiesTimer)
            this.activitiesTimer = setTimeout(() => {
                this.getData()
            }, 500)
        },
        getData () {
            this.$emit('chartLoading', true)
            this.resetChart()
            const typeAttr = this.optionAttributes.find(obj => obj.name === this.attribute)
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            const data = {
                attribute: this.attribute,
                sortBy: this.currentSel.value,
                eventAttribute: this.eventAttribute,
                filters: filters || [],
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
                timeUnit: this.timeUnit.unit,
                limit: 8,
                start: 0,
            }
            if (typeAttr.type === 'STRING' || typeAttr.type === 'BOOLEAN') {
                this.numericAttribute = false
            } else {
                this.numericAttribute = true
            }
            if (this.chart.variants) {
                data.variantCount = this.chart.variants
            }
            if (this.chart.variantId) {
                data.variantUid = this.chart.variantId
            }
            if (this.chart.loopId || this.chart.loopId === 0) {
                data.loopUid = this.chart.loopId
            }
            if (this.eventAttribute && !this.numericAttribute) {
                if (this.$refs.activitiesSelect) {
                    this.$refs.activitiesSelect.validate()
                    const valueInputError = this.$refs.activitiesSelect.hasError
                    if (valueInputError) {
                        this.emptyRows = false
                        return
                    }
                }
                data.activities = this.activities
            }
            apiRequest(Api().visualizations.attributePanel({ processId: this.processId, params: data }))
                .then((returnData) => {
                    if (returnData.rows.length === 0) {
                        this.emptyRows = true
                    } else {
                        this.emptyRows = false
                    }
                    this.updateChart(returnData)
                })
                .catch(() => {
                    notifyError()
                    this.resetChart()
                })
                .finally(() => {
                    this.$emit('chartLoading', false)
                })
        },
        resetChart () {
            this.barChartOptions.series[1].data = []
            this.barChartOptions.series[0].data = []
            this.barChartOptions.xAxis.data = []
        },
        updateChart (returnData) {
            if (returnData.data) {
                this.barChartOptions.yAxis[1].axisLabel = { show: false }
                this.barChartOptions.yAxis[0].axisLabel = { formatter: value => `${this.formatNumber(value)}` }
                this.barChartOptions.xAxis.axisLabel = { formatter: value => `${this.checkDate(value)}` }
                this.avg = this.App.numberLocationFormat(this.formatNumber(returnData.data.avg), false, true)
                this.max = this.App.numberLocationFormat(this.formatNumber(returnData.data.max), false, true)
                this.min = this.App.numberLocationFormat(this.formatNumber(returnData.data.min), false, true)
                this.sd = this.App.numberLocationFormat(this.formatNumber(returnData.data.sd), false, true)
                this.barChartOptions.tooltip = {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        // eslint-disable-next-line max-len
                        let tooltipContent = `<div><div style="width: 10px; height: 10px; background-color: ${params[0].color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                        tooltipContent += `${this.checkDate(params[0].name)}: ${(params[0].value).toFixed(2)} <br>`
                        tooltipContent += '</div>'
                        return tooltipContent
                    },
                }
            } else {
                this.barChartOptions.yAxis[1].axisLabel = { show: true }
                this.barChartOptions.yAxis[0].axisLabel = { formatter: value => `${this.formatNumber(value)}${this.range}` }
                this.barChartOptions.xAxis.axisLabel = {
                    formatter: (value) => {
                        const maxLength = 8
                        // eslint-disable-next-line no-restricted-globals
                        if (!isNaN(value) && typeof value === 'number') {
                            return this.formatNumber(value)
                        }
                        if (value.length > maxLength) {
                            return `${value.slice(0, maxLength)}...`
                        }
                        return `${value}`
                    },
                }
                this.barChartOptions.tooltip = {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div style="max-width: 15em;">'
                        tooltipContent += `${this.lineBreak(params[0].name)}`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            const { color } = param
                            // eslint-disable-next-line max-len
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            if (seriesName === this.$t('visualization.customPlotPanels.duration')) {
                                tooltipContent += `${seriesName}: ${moment.duration(value, this.maxDur).format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false })} <br>`
                            } else {
                                tooltipContent += `${seriesName}: ${value} <br>`
                            }
                            tooltipContent += '</div>'
                        })
                        return tooltipContent
                    },
                }
            }
            this.maxDur = this.rangeDuration(Math.max.apply(null, [returnData.rows.map(item => (item.duration))][0]))
            const durationArr = returnData.rows.map((item) => {
                if (this.numericAttribute) {
                    return item.value
                }
                return this.formatSeconds(item.duration, this.maxDur)
            })

            const frequencyArr = [returnData.rows.map(item => item.frequency)][0]
            const commonAttributes = [returnData.rows.map(item => item.attribute)][0]
            this.barChartOptions.series[1].data = frequencyArr
            this.barChartOptions.series[0].data = durationArr
            this.barChartOptions.xAxis.data = commonAttributes
        },
        lineBreak (inputString) {
            let resultado = ''
            for (let i = 0; i < inputString.length; i += 22) {
                const linea = inputString.substr(i, 22)
                resultado += `${linea }<br>`
            }
            return resultado
        },
        getActivities () {
            if (this.chart.activities) {
                this.chartActivities = [this.chart.activities.map(activity => activity.name)]
            }
        },
        checkDate (value) {
            if (this.timeUnit.unit === 'day') {
                return moment(value).format('DD/MM/YY')
            }
            if (this.timeUnit.unit === 'month') {
                return moment(value).format('MM/YYYY')
            }
            return value
        },
    },
}// eslint-disable-next-line
</script>
<style lang="scss" scoped>
.attrSelect {
    width: 17em;
    height: auto;
    margin: 1em;
    margin-top: 0em;
    margin-bottom: 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chart-container {
    margin: 0 auto;
    overflow: auto;

    .chart {
        width: 285px;
        height: 180px;

        &+.chart {
            margin-left: 30px;

            @media screen and (max-width: $lg) {
                margin-left: 0px;
            }
        }
    }

    .openIcon {
        height: fit-content;
        padding-bottom: 0px;
        margin-left: 0 auto;
    }
}
.Field {
    width: 18em;
    height: auto;
    margin: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    &:deep(.q-field__control) {
            height: 50px;
            .q-field__control-container .q-field__native span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    line-height: 34px;
            }
            .q-field__append {
                height: 50px;
            }
    }
}
.timeUnitField {
    width: fit-content;
    min-width: 150px;
    height: auto;
    margin: 2em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
/* Selector específico para aplicar solo a Dropdown */
:deep(.Dropdown .Items) {
    gap: 10px;
    white-space: nowrap;
    flex-wrap: nowrap;
}

:deep(.Dropdown .Items .LeftIcon) {
    margin-right: 0;
    display: flex;
    flex-direction: column;
}

:deep(.Dropdown .Items .Text) {
    align-items: center;
    display: flex;
    flex-direction: column;
}
</style>
