/* eslint-disable max-len */
export default {
    back: 'Voltar',
    continue: 'Continuar',
    finish: 'Terminar',
    cancel: 'Cancelar processo',
    testing: 'Teste de ligação',

    trace: 'Identificação do traço',
    activity: 'Atividade',
    startTime: 'Hora de início',
    endTime: 'Hora de fim',
    relevant: 'Atributo relevante',

    activityWarning: 'O campo selecionado contém muitos valores diferentes. Por favor, certifique-se de que corresponde à coluna de Atividade real no log de processo carregado.',

    name: 'Nome do atributo',
    description: 'Descrição do ficheiro',
    file: 'Ficheiro',

    notifications: {
        keyFieldsSuccess: {
            text: 'Configuração do campo-chave criada com êxito.',
        },
        keyFieldsError: {
            text: 'Verificar a configuração do campo-chave.',
        },
        keyRelevantFieldsLoading: {
            text: 'Carregando atributos relevantes, aguarde.',
        },
        keyRelevantFieldsCountError: {
            text: 'Tem de selecionar pelo menos 1 atributo relevante.',
        },
        keyRelevantFieldsError: {
            text: 'Os atributos relevantes devem ser do tipo STRING',
        },
        launched: {
            dataset: {
                text: 'Conjunto de dados carregado com sucesso.',
            },
            discovery: {
                text: 'Descoberta iniciada com êxito.',
            },
            import: {
                text: 'O processo de importação de dados foi iniciado com êxito.',
            },
            query: {
                text: 'O Insight foi lançado com êxito.',
            },
        },
        connection: {
            text: 'Ligação iniciada com êxito',
        },
    },

    modals: {
        cancel: {
            text: 'Tem a certeza de que pretende abandonar este processo?',
        },
    },

    configs: {
        dataset: {
            title: 'Assistente de importação de ficheiros',
            steps: {
                0: 'Definições',
                1: 'Carregar ficheiro',
                2: 'Resumo',
                3: 'Tipos de dados',
            },
        },

        query: {
            title: 'Assistente de informação',
            steps: {
                0: 'Conjunto de dados',
                1: 'Definição de perspetiva',
            },
        },

        import: {
            title: 'Assistente de ligação',
            steps: {
                1: 'Serviço',
                2: 'Dados de ligação',
                3: 'Fluxos de trabalho',
                4: 'Resumo',
            },
        },
    },

    preview: {
        step: 'Etapa',
        select: 'Selecionar',
        column: 'coluna',
        optional: 'Opcional',
        decimalCharacter: 'Carácter decimal',
        thousandCharacter: 'Carácter de milhar',
        homogeinzeAttribute: 'Homogeneizar atributos categóricos',
        numberFormat: {
            label: 'Formato numérico',
            placeholder: '',
        },
        numbersFormats: {
            en: 'Americano',
            eu: 'Europeu',
        },
        warning: 'AVISO: ',
        mismatchedNumberFormat: 'Formato diferente do configurado na sua conta. Certifique-se de que formato numérico está a ser utilizado nos dados carregados.',
        dataTypesTitle: 'Ajustar tipos de dados',
        relevantNoAttributesSelectedWarning: 'Nenhum atributo relevante selecionado. O facto de não ter os atributos relevantes configurados significa que não terá acesso ao impulsionador de desempenho.',
        relevantAttributesDescription: 'Modifique os tipos de atributos',
        done: {
            title: 'Feito',
            text: 'Reveja as suas selecções e prossiga.',
            noColumnsLeft: 'Não existem colunas para selecionar atributos relevantes.',
        },

        tooltips: {
            ACTIVITY: `Este campo deve conter o nome ou código da atividade que está associada a cada evento cadastrado (por exemplo, "criação de pedido" ou "embalagem de produto").`,
            START: 'Este campo deve conter a hora de início de um evento registado.',
            END: `Este campo deve conter a hora de fim de um evento registado.`,
            TRACE: `Este campo deve identificar de forma única cada execução diferente do processo.`,
            RELEVANT: `Este campo é considerado relevante para o seu processo`,
        },

        modal: {
            dateformat: 'Adicionar formato de data',
            time: 'Formato da hora',
            text: {
                one: 'O formato da hora deve estar em conformidade com a norma ISO 8601',
                two: 'Pode ver mais informações sobre formatos de data neste',
            },
            website: 'sítio Web',
            example: 'Exemplo de formato',
        },
    },

    report: {
        name: 'Nome do relatório',
        type: 'Tipo de relatório',
    },

    query: {
        mode: 'Modo de análise',
        query: 'Insight',
        model: 'Modelo',
        restrictions: 'Restrições do Insight',
        queries: {
            seq: 'SEQ',
            switch: 'COMUTAÇÃO',
            fork: 'FORQUILHA',
            loop: 'LOOP',
            activities: 'Actividades',
        },
        insight: {
            model: {
                title: 'MODELO DE INSPECÇÃO',
                description: `An insight model is simply the workflow structure that will be used to determine which execution traces fit the process as it has been conceived.
                The InVerbis Process Language (IPL) allows to easily define process models that contain gates and activities in a similar manner to BPMN representations.
                Using just 4 keywords, an user can model any process that contains activity sequences, parallels, choices, and loops by nesting these elements.
                `,
                seq: {
                    title: 'SEQ("Nome da atividade" | Estrutura aninhada, ...):',
                    description: `This keyword defines a sequence of activities or structures that must be executed following a precedence order (from left to right).
                    Both activities and structures can be mixed within the same SEQ, always separated by commas. Examples:`,
                    examples: [
                        'SEQ("Ordem criada")',
                        'SEQ("Ordem criada", "Notificar gestor")',
                        'SEQ("Ordem criada", "Notificar gestor",SWITCH("Ordem aceite", "Ordem rejeitada))',
                    ],
                },
                switch: {
                    title: 'SWITCH("Nome da atividade" | Estrutura aninhada, ...):',
                    description: `This keyword defines a choice or selection among different activites or substructures, with only one of them being executed in a specific trace. Examples:`,
                    examples: [
                        'SWITCH("Pagamento com cartão", "Pagamento por PayPal")',
                        'SWITCH(SEQ("Enviar correio eletrónico", "Receber correio eletrónico"),SEQ("Enviar SMS", "Receber chamada telefónica"))',
                        'SEQ("Bilhete aberto",SWITCH("Escalar", "Gerir"))',
                    ],
                },
                fork: {
                    title: 'FORK("Nome da atividade" | Estrutura aninhada, ...):',
                    description: `This keyword defines a set of activities or process substructures that must be executed regardless of their order, thus covering parallel or concurrent activities. Examples:`,
                    examples: [
                        'FORK("Notificar utilizador", "Notificar gestor")',
                        'SEQ("Triagem",FORK("Análise de sangue", "TAC"), "Análise de resultados", "Cirurgia")',
                    ],
                },
                loop: {
                    title: 'LOOP("Nome da atividade" | Estrutura aninhada, ...):',
                    description: `This keyword defines a set of activities or process substructures that can be executed several times in a row. Currently,
                    this keyword represents a 0..* repetition, and is likely to change in the future to a configurable n..*.
                    This means that, for instance, to specify an activity that is repeated at least once: `,
                    examples: [
                        'SEQ("Notificar o utilizador",LOOP("Notificar o utilizador"))',
                    ],
                },
                custom: {
                    description: `Likewise, if we need to specify that an activity or submodel must execute at least twice, the user will have to explicitly include in the model the minimum number of times that must be repeated:`,
                    examples: [
                        'SEQ("Notificar utilizador", "Notificar utilizador",LOOP("Notificar utilizador"))',
                    ],
                },
            },
            restrictions: {
                copy: 'Operador copiado para a área de transferência',
                title: `INSIGHT RESTRICTIONS`,
                description: `Restrictions allow to further constraint the result of an insight by filtering traces that, in addition to verifying the insight model,
                must also verify certain conditions that can be defined on attributes and durations.
                Restrictions are evaluated as boolean conditions, but we provide several logical,
                 numerical and date/time operations that allow to specify complex constraints. The list of operations we currently support is:`,
                operations: [
                    'date(dd,MM,yyyy)',
                    'dia(número)',
                    'semana(número)',
                    'mês(número)',
                    'before(datetime,datetime)',
                    'after(datetime,datetime)',
                    'eq(número,número)',
                    'gt(número,número)',
                    'gte(número,número)',
                    'lt(número,número)',
                    'lte(número,número)',
                    'or(boolean,boolean)',
                    'and(boolean,boolean)',
                    'sum(número,número)',
                    'minus(número,número)',
                    'prod(número,número)',
                    'div(número,número)',
                    'between(a, x, y)',
                    'traceDuration()',
                    'carimbo de data/hora("Atividade")',
                    'duração("Atividade")',
                    'timeBetween("Actividade1", "Actividade2")',
                    'atributo("Atividade", "Nome do atributo", "tipo")',
                ],
                examples: {
                    title: 'Exemplos',
                    items: [
                        'and(between(timestamp("O_Created"),',
                        'date(2,3,2002),',
                        'timestamp("O_Cancelled")),',
                        'gt(duração("O_Created"), mês(2) + semana(1)))',
                        'after(timestamp("O_Criado"),',
                        'timestamp("O_Cancelled") + month(2) + week(1))',
                        'gt(timeBetween("O_Created", "O_Cancelled"),',
                        'mês(2) + semana(1))',
                        'gt(soma(atributo("O_Criado", "MonthlyCost", float),',
                        'attrib("O_Cancelled", "MonthlyCost", float)), 1500)',
                    ],
                },
            },
        },
        options: {
            one: 'Definir um novo modelo de informação',
            two: 'Selecionar um modelo existente para verificar',
        },
    },

    connection: {
        standard: 'Padrão',
        service: 'Serviço',
        database: 'Base de dados',
        port: 'Porta',
        user: 'Utilizador',
        password: 'Palavra-passe',
        workflows: 'Fluxos de trabalho',
        filterTraces: 'Filtrar traços',
        filterEvents: 'Filtrar eventos com campos nulos',
        enable: 'Ativar',
        disable: 'Desativar',

        notifications: {
            empty: {
                title: 'Não foram encontradas vistas virtuais',
                text: 'Não foram encontrads vistas virtuais para a sua pesquisa',
            },
        },
    },

    boosterWizard: {
        chooseApproach: {
            head: 'Conjunto de dados acabado de criar',
            title: 'Escolha a sua abordagem de análise',
            performanceBooster: 'Reforço do desempenho',
            automaticInsights: 'Análises automáticas',
            customAnalysis: 'Análise personalizada',
            performanceBoosterDescription: 'Obtenha informações imediatas sobre o estado do seu processo, potenciais problemas e causas de raiz, e encontre soluções para aumentar rapidamente o seu desempenho',
            customAnalysisDescription: 'Descubra como o seu processo flui. Explore manualmente as variantes e os loops do processo, efectue análises de conformidade e aplique filtros para obter o mais ínfimo nível de detalhe',
            mainFeatures: 'Características principais',
            procOverview: 'Visão geral do processo',
            procOverviewDesc: 'Aprende como e por que o teu processo atualmente se comporta em relação a uma duração meta',
            prediction: 'Previsão',
            predictionDesc: 'Verifica como se espera que as execuções em curso se comportem para agir antecipadamente',
            simulation: 'Simulação',
            simulationDesc: 'Altera a ocorrência de atividades e tempos de espera e vê como o teu processo melhora',
            d2t: 'Relatórios de dados para texto',
            d2tDesc: 'O Inverbis vai descrever textualmente para ti os aspetos mais relevantes da tua análise de processo',
            configureInsights: 'Configurar insights automáticos',
            process: 'Descoberta de processos',
            processDesc: 'Descobre os fluxos reais de execução do teu processo e os caminhos mais relevantes',
            rework: 'Análise de retrabalho',
            reworkDesc: 'Explora potenciais gargalos e o impacto estimado no teu processo',
            conformance: 'Verificação de conformidade',
            conformanceDesc: 'Realiza uma análise de conformidade em relação a um fluxo esperado por design',
            filter: 'Sistema de filtragem',
            filterDesc: 'Aplica filtros poderosos para segmentar dados e personalizar profundamente a tua análise',
            configureManual: 'Pré-configurar análise manual',
            skipToManual: 'Ir diretamente para a análise manual',
        },
        setupOptions: {
            analysis: 'Configuração da análise',
            insights: 'Configuração de análises automáticas',
        },
        back: 'Voltar',
        skip: 'Pular configuração',
        save: 'Salvar e continuar',
        notConfigured: 'Para continuar, precisa de configurar todos os parâmetros necessários.',
        mustConfigure: 'Esta funcionalidade deve ser configurada para prosseguir para o Automatic Insights',
        performanceBooster: {
            title: 'Reforço do desempenho',
            description: 'Configure a forma como o Inverbis irá agrupar os casos em grupos de desempenho de acordo com os prazos de entrega',
            costAndCaseCompletion: {
                description: 'Configure os seguintes elementos opcionais para ajudar a Inverbis a fornecer informações mais detalhadas',
                warning: 'Não foram definidas condições de caso válidas. Os casos incompletos com durações mais curtas podem ser incluídos na análise. Isto é provável se a duração mínima (à esquerda da barra verde) for 0.',
                buttons: {
                    back: 'Voltar',
                    boost: 'Impulsionar!',
                },
            },
        },
        sla: {
            name: 'Acordo de nível de serviço (SLA)',
            description: 'Determina quando um caso deve ser considerado um desempenho aceitável se a seguinte duração for excedida. Os casos mais curtos do que este limite são considerados como cumprindo o acordo de nível de serviço (SLA) do processo',
            backButton: 'Voltar',
            nextButton: 'Próximo',
            compliance: 'Cumprir o SLA',
            nonCompliance: 'Desempenho abaixo do SLA',
            cases: 'casos',
        },
    },
}
