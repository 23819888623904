<template>
    <div
        v-click-away="handleClickAway"
        :class="dropdownClass"
        class="Dropdown"
    >
        <a
            id="btn"
            href="#"
            data-cy="dropdownBtn"
            class="Btn"
        >
            <slot>
                {{ text }}
            </slot>
            <q-icon
                v-if="icon"
                :color="iconColor"
                :name="icon"
                :size="iconSize"
            />
        </a>

        <Transition
            name="fade"
            :duration="duration"
        >
            <div
                v-if="openedInternal"
                :style="coordinatesStyles"
            >
                <q-card
                    class="Menu"
                    :style="{ maxHeight: maxHeight ? `${maxHeight}px` : 'initial' }"
                >
                    <a
                        v-for="option in options"
                        :id="option.key"
                        :key="option.key"
                        :data-cy="option.key"
                        href="#"
                        class="Link Option"
                        :aria-disabled="option.disabled ? 'true' : 'false'"
                        :class="[option.style, { separator: option.separator, disabled: option.disabled }]"
                        @click.prevent="handleClickOption(option, $event)"
                    >
                        <div class="Items flex row-left-center">
                            <q-icon
                                v-if="option.icon"
                                :name="option.icon"
                                :color="option.iconColor"
                                class="LeftIcon"
                                size="22px"
                            />
                            <WText
                                tag="span"
                                :size="14"
                                weight="semi"
                                class="Text mb-0"
                            >{{ option.label }}</WText>
                            <q-icon
                                v-if="option.iconRight"
                                :name="option.iconRight"
                                :size="14"
                                class="RightIcon"
                            />
                        </div>
                    </a>
                </q-card>
            </div>
        </Transition>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { mixin as clickawayMixin } from 'vue3-click-away'

export default {
    name: 'Dropdown',
    mixins: [clickawayMixin],
    props: {
        opened: VueTypes.bool.def(false),
        text: VueTypes.string,
        options: VueTypes.arrayOf(VueTypes.shape({
            key: VueTypes.oneOfType([Number, String]).isRequired,
            label: VueTypes.oneOfType([Number, String]).isRequired,
            disabled: VueTypes.bool.def(false),
        }).loose).isRequired,
        icon: VueTypes.string.def(''),
        iconSize: VueTypes.number.def(12),
        iconColor: VueTypes.oneOf(['primary', 'secondary', 'tertiary', 'success', 'warning', 'info', 'error', 'none', 'link']).def('primary'),
        direction: VueTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight']).def('bottomLeft'),
        withCoordinates: VueTypes.bool.def(false),
        coords: VueTypes.shape({
            x: VueTypes.oneOfType([Number, String]).isRequired,
            y: VueTypes.oneOfType([Number, String]).isRequired,
        }).loose,
        duration: VueTypes.number.def(300),
        maxHeight: VueTypes.number,
    },
    emits: ['onClick', 'onClose'],
    data () {
        return {
            openedInternal: false,
            coordinates: undefined,
        }
    },
    computed: {
        dropdownClass () {
            return [
                this.icon,
                this.direction,
            ]
        },
        coordinatesStyles () {
            return this.coordinates
                ? {
                    position: 'fixed',
                    zIndex: '10000',
                    left: `${this.coordinates.x}px`,
                    top: `${this.coordinates.y}px`,
                }
                : ''
        },
    },
    watch: {
        opened: {
            handler (value) {
                if (this.coords) {
                    this.coordinates = { ...this.coords }
                }

                this.openedInternal = value
            },
            immediate: true,
        },
    },
    methods: {
        handleClickBtn (event) {
            event.stopPropagation()
            const { clientX: x, clientY: y } = event
            if (this.withCoordinates) {
                this.coordinates = { x, y }
            }
            this.openedInternal = !this.openedInternal
        },
        handleClickOption (option, event) {
            event.stopPropagation()
            if (!option.disabled) {
                this.$emit('onClick', option)
                this.openedInternal = false
            }
        },
        handleClickAway () {
            if (this.openedInternal) {
                this.openedInternal = false
                this.$emit('onClose')
            }
        },
    },
}
</script>

<style scoped lang="scss">
.Dropdown {
    position: relative;
}

.Menu {
    position: absolute;
    display: block;
    padding: 4px 0;
    right: 0;
    min-width: 200px;
    margin: 8px 0;
    overflow-y: auto;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 100000;

    &:deep(.Body) {
        padding: 0;
    }

    // Positions
    .topLeft &,
    .topRight & {
        bottom: 100%;
        top: auto;
    }
    .bottomLeft &,
    .bottomRight & {
        top: 100%;
        bottom: auto;
    }
    .topRight &,
    .bottomRight & {
        left: 0;
        right: auto;
    }
    .topLeft &,
    .bottomLeft & {
        right: 0;
        left: auto;
    }
}

.Link {
    display: block;
    color: $link-color;
    padding: 10px 15px;

    &.Option {
        &:hover {
            background: $gray-06;
        }
    }

    .q-icon {
        margin-right: 15px;
    }

    .WText {
        line-height: 1;

        & + .q-icon {
            margin-left: 10px;
        }
    }

    &.disabled {
        opacity: .4;
        cursor: not-allowed;
    }

    &.separator, &.separator-bottom {
        border-bottom: 1px solid $gray-05;
    }

    &.separator-top {
        border-top: 1px solid $gray-05;
    }
}
</style>
