<template>
    <div class="Step">
        <div class="row justify-center">
            <q-card
                v-if="(steps || []).length"
                flat
                bordered
                class="col-md-8 col-lg-4"
            >
                <CollapseItem
                    icon="database"
                    :title="steps[0].title"
                    :opened="collapses[steps[0].name]"
                    @on-click="handleClick(steps[0].name)"
                >
                    <q-card
                        flat
                        bordered
                        class="ma-1 pa-0-5"
                    >
                        <WText>
                            {{ $t('wizard.connection.service') }}
                        </WText>
                        <WText
                            :size="12"
                            color="gray02"
                        >
                            {{ value.service.label }}
                        </WText>
                    </q-card>
                </CollapseItem>

                <div v-if="((value || {}).connection.host || (value || {}).connection.connectionChain)">
                    <CollapseItem
                        icon="key"
                        :title="steps[1].title"
                        :opened="collapses[steps[1].name]"
                        @on-click="handleClick(steps[1].name)"
                    >
                        <div
                            v-if="value.connection"
                            class="pa-1"
                        >
                            <q-card
                                flat
                                bordered
                                class="pa-0-5"
                            >
                                <div v-if="value.connection.connectionChain">
                                    <WText>
                                        JDBC
                                    </WText>
                                    <WText
                                        :size="12"
                                        color="gray02"
                                        class="Connection"
                                    >
                                        {{ value.connection.connectionChain }}
                                    </WText>
                                </div>
                                <div v-else>
                                    <div class="pa-0-5">
                                        <WText>
                                            Driver
                                        </WText>
                                        <WText
                                            :size="12"
                                            color="gray02"
                                        >
                                            {{ (value.connection.driver || {}).label }}
                                        </WText>
                                    </div>
                                    <Divider />
                                    <div class="pa-0-5">
                                        <WText>
                                            Host
                                        </WText>
                                        <WText
                                            :size="12"
                                            color="gray02"
                                        >
                                            {{ value.connection.host }}
                                        </WText>
                                    </div>
                                    <Divider />
                                    <div class="pa-0-5">
                                        <WText>
                                            {{ $t('wizard.connection.database') }}
                                        </WText>
                                        <WText
                                            :size="12"
                                            color="gray02"
                                        >
                                            {{ value.connection.database }}
                                        </WText>
                                    </div>
                                    <Divider />
                                    <div class="pa-0-5">
                                        <WText>
                                            {{ $t('wizard.connection.port') }}
                                        </WText>
                                        <WText
                                            :size="12"
                                            color="gray02"
                                        >
                                            {{ value.connection.port }}
                                        </WText>
                                    </div>
                                    <Divider />
                                    <div class="pa-0-5">
                                        <WText>
                                            {{ $t('wizard.connection.user') }}
                                        </WText>
                                        <WText
                                            :size="12"
                                            color="gray02"
                                        >
                                            {{ value.connection.username }}
                                        </WText>
                                    </div>
                                    <Divider />
                                    <div class="pa-0-5">
                                        <WText>
                                            {{ $t('wizard.connection.password') }}
                                        </WText>
                                        <WText
                                            :size="12"
                                            color="gray02"
                                        >
                                            {{ normalizePassword }}
                                        </WText>
                                    </div>
                                </div>
                            </q-card>
                        </div>
                    </CollapseItem>
                </div>
                <CollapseItem
                    icon="description"
                    :title="steps[2].title"
                    :opened="collapses[steps[2].name]"
                    @on-click="handleClick(steps[2].name)"
                >
                    <q-card
                        flat
                        bordered
                        class="ma-1 pa-0-5"
                    >
                        <WText>
                            {{ $t('wizard.connection.workflows') }}
                        </WText>
                        <WText
                            :size="12"
                            color="gray02"
                        >
                            {{ normalizeWorkFlows }}
                        </WText>
                        <div v-if="enabledFilters">
                            <Divider />
                            <div class="pa-0-5">
                                <WText>
                                    {{ $t('wizard.connection.filterTraces') }}
                                </WText>
                                <WText
                                    :size="12"
                                    color="gray02"
                                >
                                    {{ $t(`wizard.connection.${value.workflow.traces ? 'enable' : 'disable'}`) }}
                                </WText>
                            </div>
                            <Divider />
                            <div class="pa-0-5">
                                <WText>
                                    {{ $t('wizard.connection.filterEvents') }}
                                </WText>
                                <WText
                                    :size="12"
                                    color="gray02"
                                >
                                    {{ $t(`wizard.connection.${value.workflow.events ? 'enable' : 'disable'}`) }}
                                </WText>
                            </div>
                        </div>
                    </q-card>
                </CollapseItem>
            </q-card>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import { Divider, CollapseItem } from '@/components'

export default {
    name: 'StepSummary',
    components: {
        Divider,
        CollapseItem,
    },
    props: {
        value: VueTypes.shape({
            service: VueTypes.shape({
                identifier: VueTypes.string.isRequired,
                allowJdbc: VueTypes.bool,
                allowRest: VueTypes.bool,
            }).loose.isRequired,
            workflow: VueTypes.any.isRequired,
            connection: VueTypes.shape({
                restService: VueTypes.oneOfType([VueTypes.string, null]),
                connectionChain: VueTypes.oneOfType([VueTypes.string, null]),
                database: VueTypes.oneOfType([VueTypes.string, null]),
                driver: VueTypes.any,
                host: VueTypes.oneOfType([VueTypes.string, null]),
                password: VueTypes.oneOfType([VueTypes.string, null]),
                port: VueTypes.oneOfType([VueTypes.string, null]),
                username: VueTypes.oneOfType([VueTypes.string, null]),
            }).loose,
        }).loose,
        steps: VueTypes.arrayOf(VueTypes.shape({
            title: VueTypes.string,
            icon: VueTypes.string,
        }).loose),
        enabledFilters: VueTypes.bool.def(false),
    },
    data () {
        const collapses = (this.steps || []).reduce((acc, item, index) => ({ ...acc, [item.name]: !index }), {})
        return {
            collapses,
        }
    },
    computed: {
        normalizeWorkFlows () {
            return (this.value?.workflow?.workflows || []).map(({ label }) => label).join(', ') || '-'
        },
        normalizePassword () {
            const { length } = this.value?.connection?.password || Array.from({ length: 5 })
            return Array.from({ length }).map(i => '*').join('') || '-'
        },
    },
    methods: {
        handleClick (key) {
            this.collapses = {
                ...this.collapses,
                [key]: !this.collapses[key],
            }
        },
    },
}
</script>
<style scoped lang="scss">
.Connection {
    width: 100%;
    word-wrap: break-word;
}

.CollapseItem + .CollapseItem {
    border-top: 1px solid $border-color;
}
</style>
