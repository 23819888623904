<template>
    <div
        class="SLAInfo"
    >
        <div class="row justify-between q-ma-xs">
            <div class="row items-center">
                <QIcon
                    name="watch_later"
                    size="25px"
                />
                <div class="Title">
                    {{ $t(`visualization.SLAInfo.title`) }}
                </div>
            </div>
            <div class="row justify-end">
                <QBtn
                    round
                    flat
                    icon="close_fullscreen"
                    size="12px"
                    textColor="primary"
                    :disable="!customSLA ? true : false"
                    @click="visible=!visible"
                >
                    <QTooltip v-if="!customSLA">
                        {{ $t('visualization.SLAInfo.error') }}
                    </QTooltip>
                </QBtn>
            </div>
        </div>
        <div
            v-if="visible && customSLA?.length > 0"
            class="col q-pa-sm"
        >
            <div
                v-for="(customSLAPct, index) in getPercentages(cases, customSLA)"
                :key="index"
                class="row q-pa-sm"
                :class="{
                    'border-pointed': customSLA.length > 1 && customSLAPct.variantId === 'all',
                    'border': customSLA.length > 1 && customSLAPct.variantId !== 'all'
                }"
            >
                <div
                    v-if="customSLAPct.variantId !== 'all'"
                    class="col-1"
                >
                    <div
                        class="row items-center justify-center"
                        style="height: 100%;"
                    >
                        <img
                            height="30"
                            width="30"
                            :src="require(`@/assets/images/sla/${getIcon(customSLAPct)}.svg`)"
                        >
                    </div>
                </div>
                <div
                    :class="customSLAPct.variantId === 'all' ? 'col-4' : 'col-7'"
                >
                    <p
                        v-if="customSLAPct.variantId !== 'all'"
                        class="row items-center text-style q-ml-xs"
                    >
                        {{ limitTextLabelName(getLabel(customSLAPct)) }}
                    </p>
                    <p
                        v-else
                        class="row items-center text-style"
                    >
                        Cases:
                    </p>
                    <span class="q-ml-xs text-duration">{{ getDurationFormattedData(customSLA[customSLAPct.origIndex]) }}</span>
                </div>
                <div :class="customSLAPct.variantId === 'all' ? 'col-8' : 'col-4'">
                    <div
                        v-if="checkActivities(customSLAPct)"
                        class="row"
                    >
                        <div
                            :class="`${customSLAPct.nonSlaCompliancePct === 0 ? 'hidden' : ''}`"
                            class="bg-red-11 border-red"
                            :style="`width: ${customSLAPct.nonSlaCompliancePct}%;`"
                            style="height: 35px;"
                        >
                            <span
                                v-if="(customSLAPct.nonSlaCompliancePct>customSLAPct.slaCompliancePct || customSLAPct.slaCompliancePct===customSLAPct.nonSlaCompliancePct)"
                                class="textPct"
                            >{{ customSLAPct.nonSlaCompliancePct }} %</span>
                            <QTooltip>
                                {{ `${$t('visualization.SLAInfo.cases')}: ${customSLAPct.nonSlaCompliance} (${customSLAPct.nonSlaCompliancePct}%)` }}
                            </QTooltip>
                        </div>
                        <div
                            :class="`${customSLAPct.slaCompliancePct === 0 ? 'hidden' : ''}`"
                            class="bg-green-12 border-green"
                            :style="`width: ${customSLAPct.slaCompliancePct}%;`"
                            style="height: 35px;"
                        >
                            <span
                                v-if="(customSLAPct.slaCompliancePct>customSLAPct.nonSlaCompliancePct || customSLAPct.slaCompliancePct===customSLAPct.nonSlaCompliancePct)"
                                class="textPct"
                            >{{ customSLAPct.slaCompliancePct }} %</span>
                            <QTooltip>
                                {{ `${$t('visualization.SLAInfo.cases')}: ${customSLAPct.slaCompliance} (${customSLAPct.slaCompliancePct}%)` }}
                            </QTooltip>
                        </div>
                    </div>
                    <div
                        v-else
                        class="row items-center justify-center"
                        style="height: 100%;"
                    >
                        <QIcon
                            v-if="!checkActivities(customSLAPct)"
                            name="warning"
                            color="warning"
                            size="2em"
                        >
                            <QTooltip>
                                {{ `${$t('visualization.SLAInfo.noSLAInfo')}` }}
                            </QTooltip>
                        </QIcon>
                    </div>
                </div>
            </div>
        </div>
        <q-inner-loading :showing="Object.keys(info).length == 0 || variantsLoading" />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import 'moment-duration-format'
import { filtersMixin } from '@/mixins'

export default {
    name: 'SLAInfo',
    mixins: [filtersMixin],
    props: {
        info: VueTypes.object.def({}),
        chart: VueTypes.object.def({}),
        variantsLoading: VueTypes.bool.def(false),
    },
    data () {
        return {
            cases: this.info.cases,
            customSLA: this.info.customSLA,
            visible: false,
        }
    },
    watch: {
        info: {
            deep: true,
            handler () {
                this.cases = this.info.cases
                this.customSLA = this.info.customSLA
            },
        },
    },
    methods: {
        getPercentages (cases, SLAs) {
            const res = SLAs.map((sla, index) => ({
                variantId: sla.variantId,
                type: sla.type,
                origIndex: index,
                elements: sla.elements,
                nonSlaCompliance: sla.count,
                slaCompliance: cases - sla.count,
                nonSlaCompliancePct: parseFloat((sla.count / cases * 100).toFixed(2)),
                slaCompliancePct: parseFloat(((cases - sla.count) / cases * 100).toFixed(2)),
            })).filter(sla => sla.variantId === null || sla.variantId.length !== 0)
            console.log(res)
            return res
        },
        getLabel (attributes) {
            if (attributes.variantId === null) {
                if (attributes.elements.length === 1) return attributes.elements[0]
                return attributes.elements.length === 2
                    ? attributes.elements.join(' - ')
                    : `${attributes.elements[0]} ${attributes.elements[1]} - ${attributes.elements[2]} ${attributes.elements[3]}`
            }
            return attributes.variantId === 'all' ? this.$t('visualization.SLAInfo.cases') : attributes.variantId
        },
        getIcon (attributes) {
            if (attributes.variantId === null) {
                switch (attributes.type) {
                    case ('activity'):
                        return 'ACTIVITY'
                    case ('transition'):
                        return 'ARC'
                    case ('activityGap'):
                        return attributes.elements.length === 4 ? 'ACTIVITY_GAP' : 'ACTIVITY_GAP'
                    default:
                        return 'ARC'
                }
            }
            return 'VARIANT'
        },
        checkActivities (sla) {
            if (sla.type === 'activity') {
                return this.chart.activities.map(
                    act => act.name,
                ).includes(sla.elements[0])
            }
            if (sla.type === 'transition') {
                return this.chart.arcs.map(
                    act => act.name,
                ).includes(sla.elements[0])
            }
            if (sla.type === 'activityGap' && sla.elements.length === 2) {
                const firAct = this.chart.activities.map(
                    act => act.name,
                ).includes(sla.elements[0])
                const secAct = this.chart.activities.map(
                    act => act.name,
                ).includes(sla.elements[1])
                if (firAct && secAct) {
                    return true
                }
                return false
            }
            if (sla.type === 'activityGap' && sla.elements.length === 4) {
                const firAct = this.chart.activities.map(
                    act => act.name,
                ).includes(sla.elements[0])
                const secAct = this.chart.activities.map(
                    act => act.name,
                ).includes(sla.elements[2])
                if (firAct && secAct) {
                    return true
                }
                return false
            }
            return true
        },

        getDurationFormattedData (customSLA) {
            return customSLA ? moment.duration(customSLA.value, `${customSLA.unit}`).format('M[M] d[d] h[h]:m[m]', { largest: 2, trim: false }) : '-'
        },

        limitTextLabelName (text) {
            if (text.length > 20) {
                return `${text.substring(0, 20)}...`
            }
            return text
        },
    },
}
</script>
<style lang="scss" scoped>
    .SLAInfo {
        position: relative;
        background-color: #FFFFFF;
        padding:5px;
        color: $primary;
        z-index: 99;
        width: 300px;
        max-width: 500px;
        border: 1px solid $primary;
        border-radius: 5px;
        font-weight: 600;
    }
    .Title {
        margin-left: 1.7em;
    }
    .border-pointed {
        border-bottom: 2px dotted $primary;
    }
    .border-red {
        outline: 2px solid red;
        outline-offset: -2px;
    }
    .border-green {
        outline: 2px solid limegreen;
        outline-offset: -2px;
    }
    .text-style {
        color: $primary;
    }
    .textPct{
        display: flex;
        color: #000000;
        font-size: x-small;
        padding-top: 10px;
        justify-content: center;
        align-items: center;
    }
    .text-duration {
        color: grey;
        font-size: small;
    }
</style>
