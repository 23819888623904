<template>
    <div class="Tracking">
        <div>
            <WText
                weight="semi"
                :size="18"
                class="mb-0-5"
            >
                {{ $t('wizard.preview.dataTypesTitle') }}:
            </WText>
            <WText>
                {{ $t('wizard.preview.relevantAttributesDescription') }}
            </WText>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Tracking',
    props: {},
}
</script>
