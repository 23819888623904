<template>
    <div class="Preview">
        <div class="flex row-stretch-top">
            <Tracking
                class="flex-1 mb-1"
            />
        </div>
        <QTable
            bordered
            flat
            hideBottom
            :columns="normalizeColumns"
            :rows="rows"
            :loading="loading"
            :pagination="pagination"
            rowKey="index"
            separator="cell"
            class="small-table table-list"
        >
            <template #header="props">
                <QTr
                    v-if="selectable"
                    :props="props"
                    class="FakeRow"
                >
                    <QTh
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                    >
                        <div
                            v-if="col.checked || col.hovered"
                            class="Field flex row-stretch-center"
                            :class="{ optional: (fields[col.checkedIndex] || fields[col.hoveredIndex] || {}).optional}"
                        >
                            <WText
                                weight="semi"
                                tag="span"
                                color="white"
                                :size="14"
                            >
                                {{ (fields[col.checkedIndex] || fields[col.hoveredIndex] || {}).label }}
                            </WText>
                            <q-icon
                                size="18px"
                                name="close"
                                color="white"
                                clickable
                            />
                        </div>
                    </QTh>
                </QTr>
                <QTr :props="props">
                    <QTh
                        v-for="(col, index) in props.cols"
                        :key="col.name"
                        :props="props"
                        class="Header"
                        :class="{
                            hovered: hovered === col.field, cursor: col.checked,
                            disabled: col.disabledCol
                        }"
                    >
                        {{ col.label }}
                        <QSelect
                            v-model="modifiedColumns[index].type"
                            :value="col.type"
                            class="col-12 col-sm-6 col-md-4 col-lg-3 q-mt-md"
                            :disable="col.disabledCol"
                            lazyRules
                            dense
                            outlined
                            behavior="menu"
                            :options="typeOptions(col.type)"
                            @update:modelValue="updateColumnType(col)"
                        />
                    </QTh>
                </QTr>
            </template>

            <template #body-cell="props">
                <QTd
                    :props="props"
                    class="Item"
                    :class="{
                        hovered: hovered === props.col.field,
                        cursor: props.col.checked,
                        disabled: props.col.disabledCol
                    }"
                >
                    {{ props.value }}
                </QTd>
            </template>
        </QTable>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import Tracking from './Tracking.vue'

const FIELD_RELEVANT = 'relevant'

const LIMIT = 3

export default {
    name: 'Preview',
    components: {
        Tracking,
    },
    props: {
        modelValue: VueTypes.any,
        columns: VueTypes.any,
        rows: VueTypes.any,
        rawColumns: VueTypes.any,
        loading: VueTypes.bool.def(false),
        selectable: VueTypes.bool.def(true),
    },
    emits: ['update:modelValue'],
    data () {
        return {
            hovered: undefined,
            checkeds: [],
            modifiedColumns: [],
            pagination: {
                page: 0,
                rowsPerPage: 0,
            },
        }
    },
    computed: {
        normalizeColumns () {
            return (this.columns || []).map((col) => {
                const { field } = col
                const hovered = this.hovered === field
                const hoveredIndex = hovered ? this.indexEmpty() : undefined
                const checked = this.isChecked(field)
                const checkedIndex = checked ? this.indexChecked(field) : undefined
                const disabledCol = Object.values(this.modelValue).some((value) => {
                    // Previously checked as key field
                    if (typeof value === 'object' && value !== null && 'value' in value) {
                        return value.value === field
                    }
                    // Cannot be selected as relevant
                    /* if (!this.rawColumns.find(c => c.name === field).relevant) { */
                    /*     return true */
                    /* } */
                    return false
                })

                return {
                    ...col,
                    hovered,
                    hoveredIndex,
                    checked,
                    disabledCol,
                    checkedIndex,
                }
            })
        },
        fields () {
            return Array.from({ length: this.selectableFieldsCount <= LIMIT ? this.selectableFieldsCount : LIMIT }, (_, i) => ({
                key: `${FIELD_RELEVANT}${i + 1}`,
                label: `${this.$t('wizard.relevant')} ${i + 1}`,
                optional: false,
                tooltip: this.$t('wizard.preview.tooltips.RELEVANT'),
            }))
        },
    },
    created () {
        this.modifiedColumns = this.columns.map(c => ({ ...c, name: c.name, type: c.type }))
    },
    mounted () {
        this.initialize()
    },
    methods: {
        initialize () {
            this.checkeds = this.fields?.map(item => (this.modelValue[item.key] || {}).value || undefined)
            const newValue = {
                ...(this.modelValue || {}),
                modifiedTypes: this.rawColumns
                    .map(c => ({
                        ...c,
                        type: this.modifiedColumns.find(t => t.name === c.name)?.type,
                        relevant: false,
                    })),
            }
            this.notifyInput(newValue)
        },
        isChecked (field) {
            return this.checkeds.includes(field)
        },
        indexChecked (field) {
            return this.checkeds.findIndex(f => f === field)
        },
        indexEmpty () {
            return this.checkeds.findIndex(f => f === undefined)
        },
        getSlotOfField (field) {
            const currentSlot = this.indexChecked(field)
            return currentSlot !== -1 ? currentSlot : this.indexEmpty()
        },
        handleClickCol (col, checked) {
            const { field, label } = col
            const slot = this.getSlotOfField(field)
            const fieldKey = (this.fields[slot] || {}).key
            const value = {
                fieldKey, field, value: field, label, slot, checked,
            }
            this.handleInput(value)
        },
        handleInput ({
            fieldKey, field, value, label, slot, checked,
        } = {}) {
            const newValue = {
                ...(this.modelValue || {}),
                modifiedTypes: this.rawColumns
                    .map(c => ({
                        ...c,
                        type: this.modifiedColumns.find(t => t.name === c.name)?.type,
                        relevant: false,
                    })),
            }
            this.notifyInput(newValue)

            return newValue
        },
        notifyInput (value) {
            this.$emit('update:modelValue', value)
        },
        typeOptions (type) {
            const options = [type]
            if (type !== 'STRING') {
                options.push('STRING')
            }
            return options
        },
        updateColumnType (col) {
            this.handleClickCol(col, true)
        },
        getTypeOptions (type, name) {
            const numTypes = ['SHORT', 'INTEGER', 'LONG', 'DECIMAL']
            const options = []
            if (numTypes.includes(type) || type === 'TIMESTAMP' || type === 'BOOLEAN') {
                options.push(type)
            }

            const oldType = this.columns.find(c => c.name === name)?.type

            if (!options.includes('STRING') && oldType !== 'STRING') {
                options.push('STRING')
            }

            if (oldType && oldType !== type) {
                options.push(oldType)
            }

            return options
        },
        updateType (name, event) {
            this.columns = this.columns.map((c) => {
                if (c.name === name) {
                    return { ...c, type: event }
                }
                return c
            })
        },
    },
}
</script>
<style scoped lang="scss">
.small-table {
    max-height: 41vh;
    @media screen and (min-height: $md) {
        max-height: 42vh;
    }
}
.large-table {
    max-height: 53vh;
    @media screen and (min-height: $md) {
        max-height: 56vh;
    }

}
.table-list {
  padding-bottom:20px;
  &:deep() {
    flex: 1;
    color: $table-color !important;
    background: transparent !important;

    .q-table {
        tbody {
            td {
                padding: 15px;
                min-width: 130px;

                &:before {
                    background: transparent;
                }
            }
        }
    }
}

  .q-table__top,
  thead tr th {
    color: $table-head-color;
    font-size: $table-head-size;
    background: $table-head-bg;
}

  thead tr th {
    position: sticky;
    z-index: 1;
}
  thead tr:first-child th{
    top: 0;
  }
  thead tr:nth-child(2) th{
    top: 48px;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
}

  tbody{
    scroll-margin-top: 48px
}
}
.Header {
    &.hovered:not(.disabled) {
        cursor: pointer;
        &:deep(.q-checkbox__bg) {
            border-color: $white;
        }
    }
    &.disabled {
        opacity:1 !important;
    }
}

.FakeRow {
    background: $table-head-fake-bg;

    th {
        padding: 0 2px;
        border-left: 0;
        border-right: 0;
        background: $table-head-fake-bg !important;
    }
}

.Item {
    &.cursor {
        cursor: pointer
    }
    &.disabled {
        cursor: not-allowed;
    }
    &.hovered:not(.disabled) {
        background: rgba($primary, 0.05);
        cursor: pointer
    }
}

.Checkbox {
    &:deep(.q-checkbox__inner) {
        display: none;
    }
}

.Field {
    padding: 8px;
    border-radius: $border-radius;
    color: $white;
    background: $secondary;

    &.optional {
        background: $tertiary;
    }
}
.InputCharacter {
    width: 50px;
    margin-left: 10px;
}
.Warning {
    width: 80%;
    background-color: #ffe48c;
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;
    .Icon {
        margin-left: 1vw;
        margin-right: 1vw;
    }
}
</style>
