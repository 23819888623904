<template>
    <div
        class="CostCurrencySettings"
    >
        <div class="text-subtitle1 text-weight-bold text-primary q-ml-lg q-mt-lg">
            {{ $t('visualization.settings.costs.name') }}
        </div>
        <q-tabs
            v-model="tab"
            noCaps
            dense
            class="text-primary"
        >
            <q-tab
                v-for="curtab in tabs"
                :key="curtab.name"
                :label="curtab.label"
                :name="curtab.name"
            />
        </q-tabs>
        <QTabPanels
            v-model="tab"
            animated
        >
            <QTabPanel
                name="general"
                class="CostCurrencyTabs"
            >
                <div class="OptionsGroup row justify-start">
                    <q-input
                        v-model.number="number"
                        :label="$t('visualization.settings.costs.value')"
                        type="number"
                        color="primary"
                        class="col"
                        @update:model-value="updateCostCurrency"
                    />
                    <QSelect
                        v-model="unit"
                        outlined
                        class="col"
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.costs.unit')"
                        :options="curOptions"
                        @update:modelValue="updateCostCurrency"
                    />
                </div>
            </QTabPanel>
            <QTabPanel
                name="activityCosts"
                class="CostCurrencyTabs"
            >
                <div class="row justify-between">
                    <QBtn
                        color="primary"
                        icon="add"
                        noCaps
                        menuAnchor="bottom end"
                        :menuOffset="[65,0]"
                        :label="$t('visualization.settings.costs.addCost')"
                        @click="openAddCostModal"
                    />
                    <QBtn
                        icon="delete_outline"
                        color="primary"
                        noCaps
                        :label="$t('visualization.settings.costs.removeCost')"
                        @click="removeSelectedCosts"
                    />
                </div>
                <div class="TableRow row">
                    <QTable
                        v-model:selected="selected"
                        class="Table"
                        flat
                        bordered
                        hidePagination
                        :rows="activityCosts"
                        :columns="headers"
                        rowKey="activities"
                        selection="multiple"
                    >
                        <template #body-cell-name="props">
                            <QTd :props="props">
                                {{ props.row.activities }}
                                <QTooltip
                                    v-if="checkQtooltipVisible('name', props.row.activities)"
                                    anchor="top start"
                                    self="center start"
                                    :offset="[-10,0]"
                                >
                                    {{ props.row.activities }}
                                </QTooltip>
                            </QTd>
                        </template>
                        <template #body-cell-avgCostHour="props">
                            <QTd :props="props">
                                {{ props.row.avgCostHour }} {{ unit.label }}
                            </QTd>
                        </template>
                        <template #body-cell-edit="props">
                            <QTd :props="props">
                                <QBtn
                                    flat
                                    square
                                    icon="edit"
                                    size="15px"
                                    color="white"
                                    textColor="primary"
                                    class="q-mr-sm"
                                    padding="0px"
                                    @click="openEditCostModal(props.row)"
                                />
                            </QTd>
                        </template>
                        <template #no-data>
                            <div class="full-width row flex-center text-accent q-gutter-sm">
                                <span>{{ $t('visualization.settings.costs.noData') }}</span>
                            </div>
                        </template>
                    </QTable>
                </div>
            </QTabPanel>
        </QTabPanels>
        <ActivityCostModal
            ref="activityCostModal"
            :values="activityCosts"
            @save="editCostActivity"
        />
    </div>
</template>

<script>
import {
    QTabPanels,
    QTabPanel,
    QSelect,
} from 'quasar'

import VueTypes from 'vue-types'
import { notifyError } from '@/api'
import ActivityCostModal from './ActivityCostModal.vue'

const TABS = {
    GENERAL: 'general',
    ACTIVITY_COSTS: 'activityCosts',
}

export default {
    name: 'CostCurrencySettings',
    components: {
        QTabPanels,
        QTabPanel,
        QSelect,
        ActivityCostModal,
    },
    inject: ['App'],
    props: {
        values: VueTypes.object,
    },
    emits: ['CostCurrencyConfig'],
    data () {
        return {
            unit: { value: 'euro', label: this.$t('visualization.settings.costs.units.euro') },
            number: 0,
            tab: TABS.GENERAL,
            activityCosts: [],
            selected: [],
            headers: [
                {
                    name: 'activities', align: 'left', label: this.$t('visualization.settings.costs.activities'), field: 'activities', sortable: true,
                },
                {
                    name: 'avgCostHour',
                    align: 'right',
                    label: this.$t('visualization.settings.costs.avgCostHour'),
                    field: 'avgCostHour',
                    sortable: true,
                    headerClasses: 'text-right',
                },
                {
                    name: 'edit', align: 'right', label: '', field: 'edit', sortable: false,
                },
            ],
            tabs: [
                { name: 'general', label: this.$t('visualization.settings.costs.general') },
                { name: 'activityCosts', label: this.$t('visualization.settings.costs.activityCosts') },
            ],
        }
    },
    computed: {
        curOptions () {
            return [
                {
                    label: this.$t('visualization.settings.costs.units.euro'),
                    value: 'euro',
                },
                {
                    label: this.$t('visualization.settings.costs.units.dollar'),
                    value: 'dollar',
                },
                {
                    label: this.$t('visualization.settings.costs.units.pound'),
                    value: 'pound',
                },
            ]
        },
    },
    mounted () {
        this.checkIfValues()
    },
    methods: {
        getUnitLabel () {
            const option = this.curOptions.find(opt => opt.value === this.unit.value)
            return option ? option.label : ''
        },
        getLabelByValue (value) {
            const option = this.curOptions.find(opt => opt.value === value)
            return option ? option.label : ''
        },
        checkIfValues () {
            if (this.values.activityCosts) {
                this.activityCosts = this.values.activityCosts
            }
            if (this.values.cost) {
                this.unit.value = this.values.cost.unit
                this.number = this.values.cost.value
                this.unit.label = this.getLabelByValue(this.unit.value)
            }
        },
        updateCostCurrency () {
            const cost = {
                unit: this.unit.value ? this.unit.value : this.unit,
                value: this.number,
            }
            this.$emit('CostCurrencyConfig', { data: cost, type: 'cost' })
        },
        removeSelectedCosts () {
            this.activityCosts = this.activityCosts.filter(item => !this.selected.includes(item))
            this.selected = []
            this.updateActivityCosts()
        },
        editCostActivity (costData, edit, originalActivityIds) {
            if (edit) {
                const index = this.activityCosts.findIndex(item => item.activityIds === originalActivityIds)
                if (index !== -1) {
                    this.activityCosts[index] = costData
                    this.updateActivityCosts()
                }
            } else if (!this.isDuplicateActivity(costData.activities)) {
                this.activityCosts.push(costData)
                this.updateActivityCosts()
            } else {
                notifyError(this.$t('visualization.settings.costs.duplicateError'))
            }
        },
        updateActivityCosts () {
            this.$emit('CostCurrencyConfig', { data: this.activityCosts, type: 'activityCosts' })
        },
        openAddCostModal () {
            this.$refs.activityCostModal.open()
        },
        openEditCostModal (item) {
            this.$refs.activityCostModal.open(item)
        },
        checkQtooltipVisible (column, qTooltipContent) {
            const span = document.createElement('span')
            span.innerText = qTooltipContent
            span.style.fontSize = '13px'
            span.style.visibility = 'hidden'
            span.style.position = 'absolute'
            span.style.top = '-9999px'
            document.body.appendChild(span)
            const width = span.offsetWidth
            document.body.removeChild(span)
            const maxContentWidth = this.getMaxContentWidth(column)
            return width > maxContentWidth
        },
        getMaxContentWidth (column) {
            let maxContentWidth = 0
            const pageWidth = window.innerWidth
            if (column === 'activities') {
                if (pageWidth < 1600) {
                    maxContentWidth = 31
                } else if (pageWidth >= 1600 && pageWidth <= 2000) {
                    maxContentWidth = pageWidth * 0.02
                } else {
                    maxContentWidth = 61
                }
            } else if (column === 'avgCostHour') {
                if (pageWidth < 1600) {
                    maxContentWidth = 75
                } else if (pageWidth >= 1600 && pageWidth <= 2000) {
                    maxContentWidth = pageWidth * 0.05
                } else {
                    maxContentWidth = 126
                }
            }
            return maxContentWidth
        },
        isDuplicateActivity (activities) {
            return this.activityCosts.some(cost => cost.activities === activities)
        },
    },
}
</script>
<style lang="scss" scoped>
.CostCurrencySettings {
    top: $header-height;
    min-height: 300px;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    outline: 0;
    z-index: 2002;
    background: white;
    border: solid 1px rgba($black, 0.2);
    box-shadow: 0 2px 5px 0 rgba(68, 68, 68, 0.2);
    border-radius: $border-radius;
    .CostCurrency {
        margin: 20px;
    }
    .OptionsGroup {
        gap: 10px;
    }
    .WText{
        margin-top: 10px;
    }
    .SaveButtons {
        width: 80px;
    }
    .Tabs {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: -40px;
        white-space: nowrap;
        &:deep(.q-panel) {
            max-width: 95%;
        }
    }
    .CostCurrencyTabs {
        margin: 2%;
        width: 96%;
    }
    .TableRow {
        width:100%;
        max-height: 90%;
        margin-top: 30px;
        margin-bottom: 10px;
        .q-table__head .text-right {
        text-align: right;
    }
        .Table {
            flex-grow: 1;
            max-width: 100%;
            :deep(.q-table th) {
                font-weight: normal;
                font-size: 12px;
                background-color: rgb(235, 235, 235);
                position: sticky;
                top: 0;
                z-index: 99;
            }
            :deep(.q-table td) {
                max-width: 50px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            :deep(.q-table td.EditTD) {
                text-overflow: unset;
            }
        }
    }
}
</style>
